export const TestTableName = {
    Rheology: 'Rheology',
}

export const IFactTestName = {
    ThickeningTime: 'Thickening Time',
    APIRheology: 'API Rheology',
    NonAPIRheology: 'Non-API Rheology',
    UCACompStrength: 'UCA Comp. Strength',
    FreeFluidAPI10B2: 'Free Fluid API 10B-2',
    StaticGelStrength: 'Static Gel Strength',
    APIFluidLoss: 'API Fluid Loss',
}

export const TestTableNameTestNamesMapping = {
    [TestTableName.Rheology]: [IFactTestName.APIRheology, IFactTestName.NonAPIRheology]
}

export const TestStatusDescription = {
    Finished: 'Finished',
    Validated: 'Validated',
}