<div id="stage_{{ number$ | async }}" class="flx-itm-grow flx-ctn-row">
  <div class="flx-itm flx-ctn-col pump-stage-number" [style.backgroundColor]="color$ | async" (click)="toggle()">
    <div class="flx-itm flx-itm-center expanded-stage">
      <div class="flx-itm-center collapse-expanded">
        <a *ngIf="isExpandable$ | async">
          <i class="fa fa-play" [ngClass]="{'expanded': (isExpanded$ | async), 'pump-error': isInvalid }"></i>
        </a>
      </div>
      <div class="number-expanded" [ngClass]="isInvalid ? 'pump-error' : ''">
        <span>Stage</span>
        <span class="number-stage">{{ number$ | async }}</span>
      </div>
    </div>
  </div>
  <div class="flx-itm-grow flx-ctn-col stage-information">
    <stage-info [stageState]="stageState" [isPumpDefault]="isPumpDefault" [job]="job"></stage-info>

    <p-tabView [hidden]="!(isExpanded$ | async)" class="pump-stage-tab p-20 p-r-40">
      <p-tabPanel *ngIf="!(isPlug$ | async)" header="EVENTS">
        <stage-event [stageState]="stageState"></stage-event>
      </p-tabPanel>
      <p-tabPanel *ngIf="isFluid$ | async" header="FLUID DETAILS">
        <stage-fluid [fluidState]="fluidState" [stageState]="stageState" [refreshForm]="refreshForm"></stage-fluid>
      </p-tabPanel>
      <p-tabPanel *ngIf="isIFactsFluid$ | async" header="TEST RESULTS">
        <stage-test-results [stageState]="stageState"></stage-test-results>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>