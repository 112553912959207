import { delay } from 'rxjs/operators';
import {
  Component,
  ViewContainerRef,
  OnInit,
  Renderer2,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy,
  Inject
} from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmDialogService, ConfirmationDialogService, DynamicSidebarService, DynamicComponentService } from 'libs/ui';
import { DropListLocation, createEmptyDropListLocation } from '../../models';
import { HELP_FILES } from 'libs/constants';
import { environment } from 'libs/environment';
import { JobsService, UserSettingService, RoleService, EventHubService, ApplicationStateService, IfactService } from 'libs/shared/services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LazyLoadingService } from 'libs/shared/services/lazy-loading.service';
import { MODAL_ID } from 'libs/constants/modal-id.constants';
import { toastClasses } from 'libs/constants';
import { GroupTabModel } from '../../../shared/models/no-cogs-material.model';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { BrowserCacheManager } from '@azure/msal-browser/dist/cache/BrowserCacheManager';
import { DWXService } from '../../../well/services';

@Component({
  selector: 'gs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  navCollapsed = false;
  showAlertMessage = false;
  showWarningIFactDown = false;

  isCP1Opened: boolean = false;

  isShowHeader: any;
  routerId: any;
  jobs: any;

  locations = [];
  selectedLocation: DropListLocation = createEmptyDropListLocation();
  isRouteInitialized: boolean;

  toastSeverity = toastClasses;

  private readonly _destroying$ = new Subject<void>();

  get showCogs$() {
    return this.userSettingService.showCogs$;
  }

  get isJobReviewer$() {
    return this.roleService.isJobReviewer$;
  }

  get isEnhancedForCurrentGroup$() {
    return this.roleService.isEnhancedForCurrentGroup$;
  }

  public get isIframe(): boolean {

    return window !== window.parent && !window.opener;
  }

  constructor(
    private router: Router,
    private jobsService: JobsService,
    private userSettingService: UserSettingService,
    private roleService: RoleService,
    private dynamicSidebarService: DynamicSidebarService,
    private dynamicComponentService: DynamicComponentService,
    private confirmDialogService: ConfirmDialogService,
    private confirmationDialogService: ConfirmationDialogService,
    private viewContainerRef: ViewContainerRef,
    private eventHubService: EventHubService,
    private renderer: Renderer2,
    private appStateService: ApplicationStateService,
    private ifactService: IfactService,
    private bsModalService: BsModalService,
    private lazyLoadingService: LazyLoadingService,
    private _msalBroadcastService: MsalBroadcastService,
    private cd: ChangeDetectorRef,
    private dwxService: DWXService
  ) {
    this.confirmDialogService.setRootView(this.viewContainerRef);
    this.confirmationDialogService.setRootView(this.viewContainerRef);
    this.dynamicSidebarService.setRootView(this.viewContainerRef);
    this.dynamicComponentService.setRootView(this.viewContainerRef);
  }

  ngOnInit() {
    // Workaround for hiding the main body scroll when modal is opened
    this.bsModalService.onShow.subscribe(() => {
      setTimeout(() => {
        this.renderer.addClass(document.body, 'modal-open');
      }, 100);
      this.bsModalService.onHidden.subscribe(() => {
        if (this.bsModalService.getModalsCount() === 0) {
          this.renderer.removeClass(document.body, 'modal-open');
        }
      });
    });

    this.jobsService.showAlertMessage$.subscribe((data) => {
      this.showAlertMessage = data;
      this.cd.markForCheck();
    });
    this.eventHubService.onGroupUpdated$.subscribe((action) => {
      if (action !== 'selectGroup') {
        this.locations = this.userSettingService.locations;
        this.selectedLocation = this.userSettingService.selectedLocation;
      }

      this.cd.markForCheck();
    });
    this.locations = this.userSettingService.locations;
    this.selectedLocation = this.userSettingService.selectedLocation;
    this.isShowHeader = this.roleService.roles.length;
    if (this.roleService.roles.length === 0) {
      this.router.navigate(['access-denied']);
    }

    this.appStateService.notifyIFactDown$.subscribe(isDown => {
      if (isDown) {
        this.showWarningIFactDown = isDown;
        this.cd.markForCheck();
      }
    });

    this.appStateService.controlPoint1Opened$.pipe(delay(0)).subscribe((val) => {
      this.isCP1Opened = val;
      this.cd.markForCheck();
    });

    this.appStateService.openJobEdit$.subscribe(initialState => {
      this.lazyLoadingService.loadEditJobModule().then(m => {
        this.bsModalService.show(m.components.JobEditContainer, {
          initialState: initialState,
          class: 'full-screen-modal',
          ignoreBackdropClick: true,
          keyboard: false,
          id: MODAL_ID.JOB_EDIT
        });
        this.appStateService.jobEditOpened$.next(true);
      });
    });

    this.appStateService.openWellImport$.subscribe(_ => {
      this.lazyLoadingService.loadWellImportDialog().then(m => {
        const { groupId, groupName } = this.userSettingService.selectedLocation;
        //NOTE:
        //dummy data
        const indexOf = this.userSettingService.locations.indexOf(this.userSettingService.selectedLocation)
        this.dwxService.getWellsImported(groupName).subscribe(data => {
          const initState = {
            wells: data,
          }

          if (initState.wells.length > 0) {
            this.bsModalService.show(m.components.WellImportDialogComponent, {
              class: 'custom-model-screen',
              ignoreBackdropClick: true,
              keyboard: false,
              initialState: initState,
              id: MODAL_ID.WELL_NOT_IMPORT
            });
          }
          else {
            this.onCreateWell();
          }
        })
      });
    })

    this.appStateService.openCreateOption$.subscribe(() => {
      this.lazyLoadingService.loadJobCreateModule().then(m => {
        this.bsModalService.show(m.components.JobCreateOption, {
          ignoreBackdropClick: true,
          keyboard: false,
          id: MODAL_ID.JOB_CREATE
        });
      });
    });

    this.appStateService.openSecurityAdmin$.subscribe((groupTab: GroupTabModel) => {
      this.lazyLoadingService.loadAdminModule().then(m => {
        this.bsModalService.show(m.components.Modal, {
          initialState: groupTab,
          class: 'full-screen-modal',
          ignoreBackdropClick: true,
          keyboard: false,
          id: MODAL_ID.SECURITY_ADMIN
        });
      });
    });

    this.roleService.refreshUserRoles().subscribe();
  }

  onCreateWell() {
    let initialState = {
      wellId: ''
    }

    this.lazyLoadingService.loadCreateWellModule().then(m => {
      this.bsModalService.show(m.components.CreateWellContainer, {
        class: 'full-screen-modal',
        ignoreBackdropClick: true,
        keyboard: false,
        initialState: initialState,
        id: MODAL_ID.CREATE_WELL
      });
      this.appStateService.createWellOpened$.next(true);
    });
  }

  public ngOnDestroy(): void {

    this._destroying$.next(null);
    this._destroying$.complete();
  }

  setShowCogs(e) {
    this.userSettingService.showCogs = e.checked;
  }

  toggleLeftNav() {
    this.navCollapsed = !this.navCollapsed;
    this.navCollapsed ? this.renderer.addClass(document.body, 'nav-collapsed') : this.renderer.removeClass(document.body, 'nav-collapsed');
  }

  restrictNumeric(e) {
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 0) {
      return false;
    }
    if (e.which < 33) {
      return true;
    }
    // uppercase arguments
    if (e.which <= 90 && e.which >= 65) {
      return true;
    }
    // lowecase arguments
    if (e.which <= 122 && e.which >= 97) {
      return true;
    }

    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
  }

  onRouteActive() {

    this._msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => {
          return (status === InteractionStatus.None || status === InteractionStatus.HandleRedirect) && this.router.url.indexOf("access-denied") == -1;
        }),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.isRouteInitialized = true;
      });
  }

  onLocalSearch(value) {
    const basicSearch = {
      jobCode: value,
      groupId: this.userSettingService.selectedLocation.groupId
    };
    window.sessionStorage.setItem('basic-search', JSON.stringify(basicSearch));
    this.router.navigate(['search-results', 'basic', new Date().getTime()]);
  }

  selectionChanged(event) {
    const selectedGroupId = event.value.groupId;
    this.showAlertMessage = false;
    this.userSettingService.updateLastSelectedGroup(selectedGroupId).subscribe(() => {
      this.userSettingService.selectedLocation = this.selectedLocation;
      this.eventHubService.onGroupUpdated$.next('selectGroup');
      this.cd.markForCheck();
      this.roleService.refreshUserRoles().subscribe();
    });
  }

  advanceSearchClick(){
    if(this.userSettingService.locationsAll.length == 0)
    {
      this.userSettingService.getAllRegionGroups().subscribe(allLocation =>
      {
        this.userSettingService.locationsAll = allLocation
        this.showAdvanceSearch();
      });
    }
    else {
      this.showAdvanceSearch();
    }
  }

  showAdvanceSearch() {
    this.lazyLoadingService.loadAdvancedSearchModule().then(m => {
      this.bsModalService.show(m.components.Modal, {
        class: 'full-screen-modal',
        ignoreBackdropClick: true,
        keyboard: false,
        id: MODAL_ID.ADVANCE_SEARCH
      });
    });
  }

  openHelp() {
    const URL = this.router.url;
    switch (this.selectionHelpFile(URL)) {
      case 0: window.open(HELP_FILES.Navigation_Overview); break;
      case 1: window.open(HELP_FILES.Control_Point_1_Overview); break;
      case 2: window.open(HELP_FILES.Control_Point_2_Overview); break;
      case 3: window.open(HELP_FILES.Control_Point_3_Overview); break;
      case 4: window.open(HELP_FILES.Control_Point_4_Overview); break;
      case 5: window.open(HELP_FILES.Wells_Overview); break;
      case 6: window.open(HELP_FILES.Collections_Overview); break;
      case 7: window.open(HELP_FILES.Create_Fluids); break;
      case 8: window.open(HELP_FILES.Reports); break;
    }
  }

  selectionHelpFile(url: string) {
    let selectionFile = 0;
    if (url.indexOf('/control-point/cp-detail/1') !== -1) {
      selectionFile = 1;
    }
    if (url.indexOf('/control-point/cp-detail/2') !== -1) {
      selectionFile = 2;
    }
    if (url.indexOf('/control-point/cp-detail/3') !== -1) {
      selectionFile = 3;
    }
    if (url.indexOf('/control-point/cp-detail/4') !== -1) {
      selectionFile = 4;
    }
    if (url.indexOf('/wells') !== -1) {
      selectionFile = 5;
    }
    if (url.indexOf('/collections') !== -1) {
      selectionFile = 6;
    }
    if (url.indexOf('/under-construction') !== -1) {
      selectionFile = 7;
    }
    if (url.indexOf('/report') !== -1) {
      selectionFile = 8;
    }

    return selectionFile;
  }
}
