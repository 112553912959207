<div class="ifact-warning"
     *ngIf="showWarningIFactDown">
    <i class="fa fa-exclamation-triangle"></i>
    <span>The iFacts server is currently unavailable. VIDA is available in 'read only' mode only.</span>
</div>
<header *ngIf="isShowHeader>0"
        class="top-nav flex-space-between-align-centered"
        [class.top-30]="showWarningIFactDown">
    <div class="sidemenu-toggle">
        <a (click)="toggleLeftNav()"
           class="menu-toggle-button">
            <i class="fa fa-bars"
               aria-hidden="true"></i>
        </a>
    </div>
    <div class="hal-logo-sector">
        <img class="hal_logo"
             src="../../../assets/images/Cementing_HORZ_WHITE.png"
             alt="hal_logo" />
        <div class="vida-version"></div>
    </div>

    <div class="search-control">
        <input type="text"
               (keypress)="restrictNumeric($event)"
               class="search-input"
               placeholder="Search Jobs"
               #box
               (keyup.enter)="onLocalSearch(box.value.trim())">
        <label class="search-icon">
            <i (click)="onLocalSearch(box.value.trim())"
               class="fa fa-search"
               aria-hidden="true"></i>
        </label>
        <div class="search-error"
             id="search-error"
             *ngIf="showAlertMessage">
            You have entered an invalid Job Number of group: {{selectedLocation.displayName}}. Please try again.
            <i class="fa fa-times"
               aria-hidden="true"
               (click)="showAlertMessage = !showAlertMessage"></i>
        </div>
        <div class="advance-search"
            *ngIf="(isEnhancedForCurrentGroup$ | async)"
            (click)="advanceSearchClick()">
            Advanced Search</div>
    </div>

    <!-- COGS switch -->
    <div class="cogs-switch" *ngIf="isCP1Opened && (isJobReviewer$ | async)">
        Show COGS <p-inputSwitch class="align-middle ml-2" [ngModel]="showCogs$ | async" (onChange)="setShowCogs($event)"></p-inputSwitch>
    </div>

    <!-- Dashboard Nav -->
    <div id="simple-dashboard-dropdown"
         class="dropdown-container hidden">

        <dashboard-nav></dashboard-nav>
    </div>

    <!-- Standard Header -->
    <div id="standard-dropdown">
        <div class="collection">
            <p-dropdown [options]="locations"
                        [(ngModel)]="selectedLocation"
                        (onChange)="selectionChanged($event)"
                        optionLabel="displayName"></p-dropdown>

            <div class="fa fa-question-circle icon-help"
                 (click)="openHelp()"></div>
        </div>
    </div>

</header>

<div>
    <div class="main-content"
         [ngClass]="{'content-ifact-warning':showWarningIFactDown, 'nav-collapsed' :navCollapsed}">
        <dash-left-nav></dash-left-nav>
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe" (activate)="onRouteActive()"></router-outlet>
    </div>
</div>

<div *ngIf="isRouteInitialized">
    <gs-dash-user-setting></gs-dash-user-setting>
    <collection-sidebar></collection-sidebar>
    <cf-per-barrel></cf-per-barrel>
</div>

<p-toast [baseZIndex]="999999">
    <ng-template let-message pTemplate="message">
            <div class="toast-block">
                <div class="icon-wrapper">
                    <i class="pi" [class]="toastSeverity[message.severity]"></i>
                </div>
                <div class="text-wrapper">
                    <h4 *ngIf="message.summary">{{message.summary}}</h4>
                    <p innerHtml="{{message.detail}}"></p>
                </div>
            </div>
    </ng-template>
</p-toast>
