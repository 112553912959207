
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UnitType } from 'libs/constants';
import { StageStateManager } from '../../state/stage-state-manager';
import { IFactTestName, TestStatusDescription, TestTableName, TestTableNameTestNamesMapping } from './stage-test-results.constant';
import { PumpScheduleStageTest, PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { UpdateStageTests } from 'apps/vida/src/modules/pump/components/stage-test-results/test-results-mapper';

@Component({
    selector: 'stage-test-results',
    templateUrl: './stage-test-results.component.html',
    styleUrls: ['./stage-test-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageTestResultsComponent implements OnInit, OnDestroy {

    UnitType = UnitType;
    IFactTestName = IFactTestName;
    TestTableName = TestTableName;
    canEdit: boolean;
    @Input() stageState: StageStateManager;

    testTables: PumpScheduleStageTestTable[] = [];
    subscription: Subscription = new Subscription();

    apiRheologySelectedSlurry = new FormControl();
    availableAPIRheologySlurries: number[] = [];

    ngOnInit() {
        this.canEdit = this.stageState.viewState.isJobEditable;

        this.subscription.add(this.stageState.testTables$.subscribe(testTables => {
            this.testTables = testTables;
            
            const availableSlurries = this.stageState?._model?.slurry?.requestInfo?.availableSlurries;
            this.availableAPIRheologySlurries = availableSlurries?.filter(s => s.tests?.length && s.tests.some(t => TestTableNameTestNamesMapping[TestTableName.Rheology].includes(t.testName))).map(x => x.number).sort((a, b) => a - b) || [];
            this.apiRheologySelectedSlurry.patchValue(this.testTables?.find(t => t.name === TestTableName.Rheology)?.selectedSlurryNo || null);
            this.apiRheologySelectedSlurry.updateValueAndValidity();
        }));
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }

    getTestTable(testName: string): PumpScheduleStageTestTable {
        return this.testTables?.find(t => t.name === testName);
    }

    getTests(testName: string): PumpScheduleStageTest[] {
        const testTable = this.getTestTable(testName);
        return testTable?.pumpScheduleStageTests.filter(t => !t.isDeleted) || [];
    }

    isTestFinishedOrValidated(test: PumpScheduleStageTest): boolean {
        return test?.testStatusDescription === TestStatusDescription.Finished || test?.testStatusDescription === TestStatusDescription.Validated;
    }

    getField(test: PumpScheduleStageTest, fieldName: string): string {
        return test?.pumpScheduleStageTestFields?.find(t => t.testFieldDesc === fieldName)?.result;
    }

    getTemperatureField(test: PumpScheduleStageTest): string {
        const fieldName = test.testName === IFactTestName.NonAPIRheology ? 'Test Temp.' : 'Temp.';
        return this.getField(test, fieldName);
    }

    onChangeSlurryNo(e, testTableName: string) {
        const selectedSlurryNo = e.value;
        const testTable = this.getTestTable(testTableName);
        if (testTable) {
            testTable.selectedSlurryNo = selectedSlurryNo;
        }
        this.stageState._model.pumpScheduleStageTestTables = this.testTables;
        this.testTables = UpdateStageTests(this.stageState._model, this.stageState._model.slurry)?.pumpScheduleStageTestTables || [];
    }

    deleteTest(test: PumpScheduleStageTest) {
        test.isDeleted = true;
        this.stageState._model.pumpScheduleStageTestTables = this.testTables;
    }
}
