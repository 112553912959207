import { PumpScheduleStageStateManager } from './../models/pump-schedule-stage-state-manager.model';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FLUID_TYPE_SCHEDULE, SpacerMixMethod, UnitType, MIXING_PROCEDURE } from 'libs/constants';
import {
  FluidMaterialModel,
  FluidModel,
  IPlacementMethod,
  IPumpScheduleFluidType,
  ISlurryType,
  Job,
  LoadOutVolumeFluidMaterials,
  MaterialManagementMappingModel, MixingProcedure,
  PumpSchedule,
  PumpScheduleEventModel,
  PumpScheduleStageMaterialModel,
  PumpScheduleStageModel
} from 'libs/models';
import {
  ApplicationStateService,
  FluidService,
  IfactService,
  JobService,
  JobsService,
  JobStateService,
  MasterDataService,
  MaterialService,
  RoleService,
  UserSettingService
} from 'libs/shared/services';
import { ConfirmDialogService, DynamicSidebarService, JobModalManagementService } from 'libs/ui';
import { UnitConversionService } from 'libs/ui/unit-conversions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MessageService, SelectItem } from 'primeng/api';
import { BehaviorSubject, concat, Observable, of, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map, shareReplay, switchMap, switchMapTo, takeUntil, tap, reduce, first, take, finalize } from 'rxjs/operators';
import { ControlPointAdapter } from '../../control-point/adapters';
import { EditJobAdapter } from '../../edit-job/adapters';
import { PumpScheduleService } from '../../pump-schedule/services';
import { Guid, JobActionMode } from '../../shared/constant';
import { PumpScheduleFormManager } from '../form-manager';
import { ViewState } from '../view-state';
import { StageFluidCalculator } from './calculators/stage-calculator';
import { EventStateManager } from './event-state-manager';
import { FluidStateManager } from './fluid-state-manager';
import { FluidMaterialStateManager } from './material-state-manager';
import { PumpScheduleStateManager } from './schedule-state-manager';
import { StageStateManager } from './stage-state-manager';
import { guid } from 'libs/helpers';
import { PumpScheduleStageTestTable } from 'libs/models/ifact/ifacts-request-tests';
import { cloneDeep } from 'lodash';

@Injectable()
export class PumpScheduleStateFactory {

  public static readonly iFactsMaterialMixProceduresMap = {
    _: '5d11ae9b-4f15-4cc6-bf1e-e51845a01584',    // no mixing procedure from iFacts
    PB: '5d11ae9b-4f15-4cc6-bf1e-e51845a01584',
    PH: '07c80e99-5b3c-474d-b94a-34c14214fe5c',
    PM: 'cfb95677-cd4c-4b92-80ab-ff52a1760c70'
  };

  public static readonly dryWeightEnabledMaterialProcedures: string[] = [
    PumpScheduleStateFactory.iFactsMaterialMixProceduresMap.PB
  ];

  private static readonly _materialMixProceduresOrder = {
    '5d11ae9b-4f15-4cc6-bf1e-e51845a01584': 0,
    '07c80e99-5b3c-474d-b94a-34c14214fe5c': 1,
    'cfb95677-cd4c-4b92-80ab-ff52a1760c70': 2
  };

  private static readonly _thickeningTimeJobTypes: string[] = [
    'conductor casing',
    'intermediate casing',
    'production casing',
    'surface casing'
  ];

  private _job = new Job();

  private _pumpSchedule$: Observable<PumpSchedule>;

  private _pumpSchedules$ = new Subject<PumpSchedule[]>();

  private _scheduleState: PumpScheduleStateManager;

  private _listScheduleState: PumpScheduleStateManager[];

  private _listPumpScheduleStageStateManager = new BehaviorSubject<PumpScheduleStageStateManager[]>([]);

  private _currentIndex = new BehaviorSubject<number>(null);

  private _viewState: ViewState;

  private readonly _subscriptions = new Subscription();

  private _pumpScheduleReloadSubscription: Subscription = null; 

  private readonly _loadRequestSrc = new Subject();

  private readonly _loadRequest$ = this._loadRequestSrc
    .asObservable()
    .pipe(shareReplay());

  private _availableMaterialMapings$: Observable<MaterialManagementMappingModel[]> = this._jobsService.groupId$?.pipe(
    filter(groupId => !!groupId),
    switchMap(groupId => {

      const mappingsUpdate$ = this._iFactsService.forceUpdateMapping$
        .pipe(
          switchMap(_ => {
            return this._editJobAdapter.getAvailableSapMappings$(groupId);
          }),
          tap(_ => this._fluidService.materialMappingUpdated$.next()),
          shareReplay()
        );

      return concat(
        this._editJobAdapter.getAvailableSapMappings$(groupId).pipe(takeUntil(mappingsUpdate$)),
        mappingsUpdate$
      );
    }),
    shareReplay()
  );

  private readonly _allStageTypes$: Observable<IPumpScheduleFluidType[]> = this._masterDataService
    .listPumpScheduleFluidTypes()?.pipe(shareReplay());

  private readonly _slurryTypes$: Observable<ISlurryType[]> = this._masterDataService
    .listSlurryTypes()?.pipe(shareReplay());

  private readonly _stageTypes$: Observable<IPumpScheduleFluidType[]> = this._allStageTypes$?.pipe(
    map(types => {

      return types.filter(t => !t.isEnableFirstStage);
    }),
    shareReplay()
  );

  private readonly _firstStageTypes$: Observable<IPumpScheduleFluidType[]> = this._allStageTypes$?.pipe(
    map(types => {

      return types.filter(t => t.isEnableFirstStage);
    }),
    shareReplay()
  );

  private readonly _allStageTypeDropdownItems$: Observable<SelectItem<string>[]> = this._allStageTypes$?.pipe(
    map(types => {

      return this._createStageTypeItems(types);
    }),
    shareReplay()
  );

  private readonly _stageTypeDropdownItems$: Observable<SelectItem<string>[]> = this._stageTypes$?.pipe(
    switchMap(types =>
      this._allStageTypeDropdownItems$
        .pipe(
          map(all => {

            return all.filter(i => types.some(t => t.id === i.value));
          })
        )
    ),
    shareReplay()
  );

  private readonly _firstStageTypeDropdownItems$: Observable<SelectItem<string>[]> = this._firstStageTypes$?.pipe(
    switchMap(types =>
      this._allStageTypeDropdownItems$
        .pipe(
          map(all => {

            return all.filter(i => types.some(t => t.id === i.value));
          })
        )
    ),
    shareReplay()
  );

  private readonly _placementMethods$: Observable<IPlacementMethod[]> = this._masterDataService
    .listPumpSchedulePlacementMethods(1)?.pipe(shareReplay());

  private readonly _firstStagePlacementMethods$: Observable<IPlacementMethod[]> = this._masterDataService
    .listPumpSchedulePlacementMethods(0)?.pipe(shareReplay());

  public readonly _placementMethodDropdownItems$: Observable<SelectItem<string>[]> = this._placementMethods$?.pipe(
    map(methods => {

      return this._createPlacementMethodItems(methods);
    }),
    shareReplay()
  );

  public readonly _firstStagePlacementMethodDropdownItems$: Observable<SelectItem<string>[]> = this._firstStagePlacementMethods$?.pipe(
    map(methods => {

      return this._createPlacementMethodItems(methods);
    }),
    shareReplay()
  );

  private readonly _materialMixProcedures$: Observable<MixingProcedure[]> = this._pumpScheduleService
    .getMixingProcedures()?.pipe(shareReplay());

  private readonly _materialMixDropdownItems$: Observable<SelectItem<string>[]> = this._materialMixProcedures$?.pipe(
    map(procedures => {

      return this._createMaterialMixItems(procedures);
    })
  );

  private readonly listStageModelsByStageSrc$: Observable<any> = this.listPumpScheduleStageStateManager$.pipe(
    map((pSStageStateManager: PumpScheduleStageStateManager[]) => pSStageStateManager.map(x => x.scheduleState)),
    map((scheduleState: PumpScheduleStateManager[]) =>scheduleState.map(i => {
        const { _stagesStatesSrc } = i;
        let listStage = _stagesStatesSrc.value.map(item => item._model);
        return listStage;
    }))
);

  public isFirstStageRemovable$ = new BehaviorSubject<boolean>(true);

  public isFirstStageRemovableObv = this.isFirstStageRemovable$.asObservable();

  public constructor(

    private readonly _pumpScheduleService: PumpScheduleService,

    private readonly _jobService: JobService,

    private readonly _jobsService: JobsService,

    private readonly _jobStateService: JobStateService,

    private readonly _materialService: MaterialService,

    private readonly _masterDataService: MasterDataService,

    private readonly _roleService: RoleService,

    private readonly _iFactsService: IfactService,

    private readonly _fluidService: FluidService,

    private readonly _applicationStateService: ApplicationStateService,

    private readonly _jobModalManagementService: JobModalManagementService,

    private readonly _userSettingsService: UserSettingService,

    private readonly _unitConversionService: UnitConversionService,

    private readonly _confirmDialogService: ConfirmDialogService,

    private readonly _dynamicSidebarService: DynamicSidebarService,

    private readonly _modalService: BsModalService,

    private readonly _messageService: MessageService,

    private readonly _editJobAdapter: EditJobAdapter,

    private readonly _controlPointAdapter: ControlPointAdapter,

    private readonly _formManager: PumpScheduleFormManager
  ) {
    this._constructDefaultListPumpScheduleStageStateManager()
  }

  private _constructDefaultListPumpScheduleStageStateManager() {
    const scheduleState = this.createScheduleState(
      this._job,
      1,
      "Pump Schedule 01",
      'init',
      null,
      true
    )
    const defaultIndex = 0
    this._currentIndex.next(defaultIndex);
    const initPump = {
      displayText: "Pump Schedule 01",
      index: defaultIndex,
      isHidden: false,
      scheduleState,
      model: scheduleState.form.value,
    }
    this.pushListPumpScheduleStageStateManager(initPump)
    const model = this.getModel()
    this.setListPumpScheduleStageStateManagerItem({ ...initPump, model }, defaultIndex)
  }

  public getIsPumpScheduleDefault() {
    return false;
  }

  public updateCurrentStageRemovable() {
    // When pump order is 0,
    // if have only one stage => disable "Delete Stage" button
    // else if have more => enable "Delete Stage" button
    const isFirstStageRemovable = this.listPumpScheduleStageStateManager[this.currentIndex]?.model?.stages?.length > 1
    this.isFirstStageRemovable$.next(isFirstStageRemovable);
  }

  public get viewState(): ViewState {

    return this._viewState;
  }

  public get totalBlendCO2e$(): Observable<number> {

    return this._scheduleState.totalBlendCo2e$;
  }
  public get totalBlendActualCO2e$(): Observable<number> {

    return this._scheduleState.totalBlendActualCo2e$;
  }

  public get pumpSchedule$(): Observable<PumpSchedule> {

    return this._loadRequest$?.pipe(
      switchMap(_ => {

        return this._pumpSchedule$;
      }),
      shareReplay()
    );
  }

  public get pumpSchedules$(): Observable<PumpSchedule[]> {
    return this._loadRequest$
      .pipe(
        switchMap(_ => {
          return this._pumpSchedules$;
        }),
        shareReplay()
      );
  }

  public get listPumpScheduleStageStateManager$(): Observable<PumpScheduleStageStateManager[]> {
    return this._listPumpScheduleStageStateManager
      .asObservable().pipe(shareReplay());
  }

  public get listPumpScheduleStageStateManager() {
    return this._listPumpScheduleStageStateManager.value
  }

  public get setCurrentIndex$(): Observable<number> {
    return this._currentIndex.asObservable().pipe(shareReplay());
  }

  public get currentIndex() {
    return this._currentIndex.value;
  }

  public destroy(): void {

    this._scheduleState.destroy();
    // this._listScheduleState.map(scheduleState => scheduleState.destroy())
    this._loadRequestSrc.complete();
    this._subscriptions.unsubscribe();

    // 2 lines below look looks like a hack. But could not find which subscriptions
    // keep references to controlAdpter.fluids$ and editJobAdapter.fluids$.
    // Control Point components make a lot of subscriptions without unsubscribing at destroy.
    // These kept subscriptions make number of fluids emission to increase each time by 1.
    // Pump Schedule is created in Job Edit or any Control Point.
    // Consider make this factory a singleton for the app and have 1 copy of Pump Schedule for the app.

    this._controlPointAdapter.fluids$.observers.forEach(o => o.complete());
    this._editJobAdapter.fluids$.observers.forEach(o => o.complete());
  }

  public destroyListPumpManager(): void {
    this._listPumpScheduleStageStateManager.value.forEach(s => s.scheduleState.destroy());
    this._listPumpScheduleStageStateManager.complete()
  }

  public updateListPumpScheduleStageStateManager(list: PumpScheduleStageStateManager[]) {

    if (!list || list.length == 0) {
      return;
    }

        let updated = false;

        list.forEach(pumpSchedule => {
            let number = 1;
            if (!pumpSchedule?.model?.stages) return;
            pumpSchedule.model.stages.forEach((stage) => {
                if (stage && (stage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.BOTTOM_PLUG
                    || stage.pumpScheduleFluidTypeName === FLUID_TYPE_SCHEDULE.TOP_PLUG_START_DISPLACEMENT)) {

                    if(stage.number != -1) {
                        updated = true;
                        stage.number = -1;
                    }
                } else {
                    let newNumber = number++

                    if(stage.number != newNumber) {
                        updated = true;
                        stage.number = newNumber;
                    }

                }
            });
        });

        this._listPumpScheduleStageStateManager.next(list);

        if (updated) {
            this._scheduleState.notifyFluidsUsage();
        }
    }

  public destroyCurretnIndex(): void {
    this._currentIndex.complete()
  }

  public updateCurrentIndex(index: number) {
    this._currentIndex.next(index)
  }

  public pushListPumpScheduleStageStateManager(item: PumpScheduleStageStateManager) {
    const currentList = this.listPumpScheduleStageStateManager
    currentList.push(item)

    this.updateListPumpScheduleStageStateManager(currentList);
  }

  public forEachListPumpScheduleStageStateManager(callback: any) {
    if (!callback) return;
    const currentList = this.listPumpScheduleStageStateManager
    currentList.forEach(callback)

    this.updateListPumpScheduleStageStateManager(currentList);
  }

  public setListPumpScheduleStageStateManagerItemByPumpId(currentModel: PumpSchedule) {

    if (this.currentIndex === null) return;
    if (currentModel === null) return;


    const currentStageState = this.listPumpScheduleStageStateManager[this.currentIndex];
    const newStageState = { ...currentStageState, model: currentModel };

    this.setListPumpScheduleStageStateManagerItem(newStageState, this.currentIndex);
  }
  public setListPumpScheduleStageStateManagerItem(item: PumpScheduleStageStateManager, index: number) {
    const currentList = this.listPumpScheduleStageStateManager
    currentList[index] = item

    this.updateListPumpScheduleStageStateManager(currentList);
  }

  public deleteListPumpScheduleStageStateManagerItem(index: number, callback?: any) {
    const currentList = this.listPumpScheduleStageStateManager
    const length = currentList.length
    if (index === length - 1) {
      currentList.pop()
    } else {
      currentList.splice(index, 1)
    }

    const updatelist = currentList.map(
      (item, index): PumpScheduleStageStateManager => {
        const nameSplitArr = item.displayText.split(' ')
        nameSplitArr.pop()

        if (nameSplitArr.length > 0) {
          const currentIndex = index + 1
          const displayText = `${nameSplitArr.join(' ').trim()} ${currentIndex < 10 ? '0' : ''}${currentIndex}`

          const model = item.model
          model.name = displayText;

          return {
            ...item,
            index,
            displayText: model.name,
            model
          }
        } else {
          const model = item.model
          model.name = item.displayText;

          return {
            ...item,
            index,
            displayText: model.name,
            model
          }
        }




      }
    )
    this.updateListPumpScheduleStageStateManager(updatelist);

    callback && callback(updatelist)
  }

  public reload(): void {

    this._loadRequestSrc.next();
  }

  public getModel(forSave: boolean = false, updateCache: boolean = true, callback: any = undefined): PumpSchedule {
    let scheduleSnapshot: PumpSchedule = null;

    const dataRequest = new Subject();

    if (this._scheduleState.model$) {
      this._scheduleState.model$.subscribe(data => {
        callback && callback(data)
      })

      const subscription = this._scheduleState.model$
        .pipe(
          switchMap(schedule =>
            dataRequest.asObservable()
              .pipe(
                map(_ => {
                  schedule.controlPointType = this._viewState.controlPointNumber;
                  schedule.name = this._viewState.Name;
                  if (forSave && this._job.isClonedJob) {

                    this._clearModelIds(schedule);
                  }
                  return schedule;
                })
              )
          )
        )
        .subscribe(schedule => {
          if (updateCache) {

            this._applicationStateService.pumpScheduleCache$.next(schedule);
          }
          scheduleSnapshot = schedule;
        });


      dataRequest.next();
      dataRequest.complete();
      subscription?.unsubscribe();
    }

    return scheduleSnapshot;
  }

  public updateCurrentModel() {
    const currentModelList = this.getListModel()[this.currentIndex];
    const model = this.getModel();

    model && currentModelList && this.listControlEachPump.map(field => {
      if (model[field]) { currentModelList[field] = model[field]; }
    });

    if (this.currentIndex === null) return;
    if (currentModelList === null) return;

    const currentStageState = this.listPumpScheduleStageStateManager[this.currentIndex];
    const newStageState = { ...currentStageState, model: currentModelList };

    const currentList = this.listPumpScheduleStageStateManager;
    currentList[this.currentIndex] = newStageState
    const updatedStageSlurryList = this.updatedSlurryOfPumpStageByListFluid(currentList);
    this.updateListPumpScheduleStageStateManager(updatedStageSlurryList);
  }

  public updatedSlurryOfPumpStageByListFluid(listModel) {
    const listFluid = this.getFluidFormArrayData();
    const listModelPump = this.getListModel();
    const fieldEmitToChanged = [
      'slurryTypeId',
      'name',
      'slurryType',
      'fluidAdditiveMaterial',
      'fluidBlendMaterial',
      'fluidMaterial',
      'supplementalMaterial',
      'isCementBlend',
    ]
    const listPump = listModelPump.map(itemPump => {
      return {
        ...itemPump,
        stages: !!itemPump.stages && itemPump.stages.map(itemStage => {
          if (!!listFluid.length) {
            const slurryStageChanged = listFluid.find(data => !!itemStage.slurry && !!itemStage.slurry.id && data.id === itemStage.slurry.id);
            const slurryStageMapped = new Object();
            fieldEmitToChanged.map(field => {
              if (field && slurryStageChanged && !!slurryStageChanged[field]) {
                slurryStageMapped[field] = slurryStageChanged[field];
              }
            })
            const slurry = slurryStageChanged !== undefined ? { ...itemStage.slurry, ...slurryStageMapped } : itemStage.slurry
            return {
              ...itemStage,
              slurry
            }
          } else {
            return itemStage
          }
        })
      }
    })

    const listModelUpdated = listModel.map((item, index) => {
      return {
        ...item,
        model: listPump[index]
      }
    });

    return listModelUpdated;
  }

  public getFluidFormArrayData = () => {
    return this._editJobAdapter.fluidFormArray?.value
  }

  public getListStageStageSrcEachPump(): void {
    let listStageModelsByEachPump;
    this.listStageModelsByStageSrc$.subscribe(
        model => { listStageModelsByEachPump = model }
    )
    return listStageModelsByEachPump;
  }

  public getListModel(forSave: boolean = false, updateCache: boolean = true): PumpSchedule[] {
    const stagesEachPump = this.getListStageStageSrcEachPump();
    if (this.listPumpScheduleStageStateManager.length > 1) {
      return this._listPumpScheduleStageStateManager.value.map(
        (item, index) => {
          const stages = stagesEachPump[index];

          const model = {...item.model, stages};
          return {
            ...model,
            order: index,
            Name: item.displayText,
            pumpScheduleId: item.model?.pumpScheduleId ? item.model.pumpScheduleId : '',
          }
        }
      )
    } else {
      const model = this.getModel()
      if (this.listPumpScheduleStageStateManager !== null) {
        if (this.listPumpScheduleStageStateManager[0]?.model) {
          const stages = stagesEachPump[0];
          return !!stages ? [{ ...model, order: 0, stages }] : [{ ...model, order: 0 }]
        } else {
          return [{ ...model, order: 0 }]
        }
      } else {
        return [{ ...model, order: 0 }]
      }
    }
  }

  public getFluidMaterialsForCF3(): LoadOutVolumeFluidMaterials[] {
    const pumpSchedules = this.getListModel();

    if (pumpSchedules === null) {
      return [];
    }

    return pumpSchedules.reduce((acc, ps) => {
      ps.stages.forEach((stage: PumpScheduleStageModel) => {
        stage.fluidMaterials.forEach(fluidMaterial => {
          if (this.isLiquidMaterial(fluidMaterial)) {
            return;
          }

          acc.push({
            pumpScheduleId: ps.pumpScheduleId,
            stageId: stage.id,
            fluidMaterialId: fluidMaterial.fluidMaterialId,
            order: stage.order,
            loadOutVolume: fluidMaterial.loadoutVolume,
            name: fluidMaterial.fluidMaterialName,
            plannedQty: fluidMaterial.plannedQty,
            actualQty: fluidMaterial.actualQty,
            pumpScheduleStageMaterialId: fluidMaterial.id,
          } as LoadOutVolumeFluidMaterials);
        });
      });
      
      return acc;
    }, [] as LoadOutVolumeFluidMaterials[]);
  }

  public getPumpScheduleStageTests() {

    const pumpSchedules = this.getListModel();

    return pumpSchedules?.reduce((acc, ps) => {
      ps.stages.forEach((stage: PumpScheduleStageModel) => {
        if (stage.id && stage.pumpScheduleStageTestTables) {
          acc = acc.concat(stage.pumpScheduleStageTestTables);
        }
      })
      return acc;
    }, [] as PumpScheduleStageTestTable[]) || [];
  }

  private isLiquidMaterial(material: PumpScheduleStageMaterialModel) {
    if (!material) {
      return false;
    }

    return material?.loadoutVolumeUnit === UnitType.SmallVolume ||
      material?.mixingProcedureValue === MIXING_PROCEDURE.PH ||
      material?.overrideMixingProcedure?.value  === MIXING_PROCEDURE.PH;
  }

  public getListModelDry(forSave: boolean = false, updateCache: boolean = true): Observable<any[]> {
    const stagesEachPump = this.getListStageStageSrcEachPump();
    if (this.listPumpScheduleStageStateManager.length > 1) {
      var list = this._listPumpScheduleStageStateManager.value.map(
        (item, index) => {
          return combineLatest(item.scheduleState.dryWeight$, item.scheduleState.dryVolume$).pipe(map(([dw, dv]) => {
            const stages = stagesEachPump[index];
            const model = {...item.model, stages};
            return {
              ...model,
              order: index,
              Name: item.displayText,
              pumpScheduleId: item.model?.pumpScheduleId ? item.model.pumpScheduleId : '',
              dryVolume: dv,
              dryWeight: dw
            }
          }))
        }
      )
      var result = combineLatest(list);
      return result;
    } else {
      const model = this.getModel()
      if (this.listPumpScheduleStageStateManager !== null) {
        if (this.listPumpScheduleStageStateManager[0]?.model) {
          const stages = stagesEachPump[0];
          return of(!!stages ? [{ ...model, order: 0, stages }] : [{ ...model, order: 0 }])
        } else {
          return of([{ ...model, order: 0 }])
        }
      } else {
        return of([{ ...model, order: 0 }])
      }
    }
  }


  public readonly dropdownPumpScheduleItems$: Observable<SelectItem<string>[]> = this._listPumpScheduleStageStateManager.asObservable()
    .pipe(
      map(pumpSchedules => {
        return pumpSchedules.map(pumpSchedule => {
          const item: SelectItem<string> = {
            value: pumpSchedule.model.pumpScheduleId,
            label: pumpSchedule.model.name
          };
          return item;
        });
      }),
      shareReplay()
    );

  public createHiddenScheduleState(
    job: Job,
    controlPointNumber: number = null,
    Name: string = null,
    type: 'init' | 'addNew' | 'changed' = 'init',
    pumpScheduleStageStateManager: PumpScheduleStageStateManager = null,
    isPumpDefault: boolean = false,
  ) {
    // use to calculate data when init pump schedule

    const scheduleJob = Object.assign({}, job);
    scheduleJob.pumpSchedules = this.listPumpScheduleStageStateManager.map(item => item.model);

    const forControlPoint: boolean = !!controlPointNumber;

    const getPumpSchedule = (type: 'init' | 'addNew' | 'changed'): [Observable<PumpSchedule>, number] => {
      switch (type) {
        case 'init':
          return [of(null), 0]
        case 'addNew':
          return [of(null), this.listPumpScheduleStageStateManager.length];
        case 'changed':
          return [of(pumpScheduleStageStateManager ? pumpScheduleStageStateManager.model : null), pumpScheduleStageStateManager.index];
        default:
          return [of(null), -1]
      }
    }

    const [_pumpSchedule$, scheduleIndex] = getPumpSchedule(type);
    const pumpSchedule$: Observable<PumpSchedule> = _pumpSchedule$;
    let fluidsSrc = this._editJobAdapter.fluids$;

    if (forControlPoint) {

      fluidsSrc = this._controlPointAdapter.fluids$;
    }

    return new PumpScheduleStateManager(
      scheduleJob,
      pumpSchedule$,
      fluidsSrc,
      this._allStageTypes$,
      this._stageTypeDropdownItems$,
      this._jobService,
      this._pumpScheduleService,
      this._fluidService,
      this._applicationStateService,
      this._confirmDialogService,
      this._dynamicSidebarService,
      this._modalService,
      this._messageService,
      this._editJobAdapter,
      this._formManager,
      this,
      isPumpDefault,
      scheduleIndex
    );
  }

  public createScheduleState(
    job: Job,
    controlPointNumber: number = null,
    Name: string = null,
    type: 'init' | 'addNew' | 'changed' = 'init',
    pumpScheduleStageStateManager: PumpScheduleStageStateManager = null,
    isPumpDefault: boolean = false
  ): PumpScheduleStateManager {

    if (this._scheduleState) {

      this.destroy();
    }

    this._subscriptions.add(this._jobModalManagementService.$saveSuccessJobId?.subscribe((_jobId) => {
      if (this._job.id == '')
        this._job.id = _jobId;
    }));

    this._subscriptions.add(

      this._jobModalManagementService.$saveSuccess?.subscribe(_ => {

        // After save without closing modal dialog
        // pump schedule is not reloaded in browser memory from back end.
        // If user added new stages or materials their id are still unknown (null) after save.
        // This leads to different kinds of problems.
        // Set ids for stages and materials.
        this._setIdsForCreatedStagesAndMaterials();
        this.reload();
        this._formManager.markAsPristine();
      })
    );

    this._job = Object.assign({}, job);
    this._job.pumpSchedules = this.listPumpScheduleStageStateManager.map(item => item.model);

    this._viewState = new ViewState(
      this._roleService,
      this._userSettingsService,
      this._applicationStateService,
      this._jobStateService,
      this._job,
      controlPointNumber,
      Name);

    const forControlPoint: boolean = !!controlPointNumber;

    const getPumpSchedule = (type: 'init' | 'addNew' | 'changed'): any => {
      switch (type) {
        case 'init':
          return [of(null), 0]
        case 'addNew':
          return [of(null), this.listPumpScheduleStageStateManager.length];
        case 'changed':
          return [of(pumpScheduleStageStateManager ? pumpScheduleStageStateManager.model : null), pumpScheduleStageStateManager.index];
        default:
          return [of(null), -1]
      }
    }

    const [pumpSchedule$, scheduleIndex] = getPumpSchedule(type);
    this._pumpSchedule$ = pumpSchedule$;

    let fluidsSrc = this._editJobAdapter.fluids$;

    if (forControlPoint) {

      fluidsSrc = this._controlPointAdapter.fluids$;
    }

    this._scheduleState = new PumpScheduleStateManager(
      this._job,
      this.pumpSchedule$,
      fluidsSrc,
      this._allStageTypes$,
      this._stageTypeDropdownItems$,
      this._jobService,
      this._pumpScheduleService,
      this._fluidService,
      this._applicationStateService,
      this._confirmDialogService,
      this._dynamicSidebarService,
      this._modalService,
      this._messageService,
      this._editJobAdapter,
      this._formManager,
      this,
      isPumpDefault,
      scheduleIndex
    );

    // trigger data load
    this.reload();

    return this._scheduleState;
  }


  public createStageState(stageModel: PumpScheduleStageModel, scheduleState: PumpScheduleStateManager, isPumpDefault: boolean, scheduleIndex: number): StageStateManager {

    let stageTypes$ = this._stageTypes$;
    let dropdownStageTypeItems$ = this._stageTypeDropdownItems$;
    if (!isPumpDefault) {
      stageTypes$ = combineLatest([
        this._firstStageTypes$,
        this._stageTypes$
      ]).pipe(
        map(([firstValue, value]) => {

          return [...firstValue, ...value]
        }),
        shareReplay()
      )

      dropdownStageTypeItems$ = combineLatest([
        this._firstStageTypeDropdownItems$,
        this._stageTypeDropdownItems$
      ]).pipe(
        map(([firstValue, value]) => {

          return [...firstValue, ...value]
        }),
        shareReplay()
      )
    }
    if (stageModel.order === 0 && isPumpDefault) {

      stageTypes$ = this._firstStageTypes$;
      dropdownStageTypeItems$ = this._firstStageTypeDropdownItems$;
    }

    const takeThickenningTimeFromSlurry = this._job.jobTypeName
      && PumpScheduleStateFactory._thickeningTimeJobTypes
        .some(ttj => ttj === this._job.jobTypeName.toLowerCase().trim());

    return new StageStateManager(
      { ...stageModel, pumpOrder: scheduleIndex },
      stageTypes$,
      this._slurryTypes$,
      dropdownStageTypeItems$,
      takeThickenningTimeFromSlurry,
      this._unitConversionService,
      this._applicationStateService,
      this._formManager,
      scheduleState,
      this,
      this._fluidService,
    );
  }

  public createEventState(
    eventModel: PumpScheduleEventModel,
    stageOrder: number,
    isCementEvent: boolean
  ): EventStateManager {

    return new EventStateManager(
      eventModel,
      stageOrder,
      stageOrder === 0,
      isCementEvent,
      stageOrder === 0 ? this._firstStagePlacementMethods$ : this._placementMethods$,
      this._confirmDialogService,
      this._formManager,
      this
    );
  }

    public createFluidState(
        form: UntypedFormGroup,
        fluid$: Observable<FluidModel>,
        stageModel: PumpScheduleStageModel,
        materialsStatesSource: BehaviorSubject<FluidMaterialStateManager[]>,
        stageCalc: StageFluidCalculator,
        spacerMixMethod$: Observable<SpacerMixMethod>
    ): FluidStateManager {

    return new FluidStateManager(
      form,
      fluid$,
      stageModel,
      materialsStatesSource,
      stageCalc,
      this._editJobAdapter.slurryTypeChanges$,
      this._slurryTypes$,
      spacerMixMethod$,
      this._materialService,
      this._unitConversionService,
      this._formManager,
      this
    );
  }

  public createFluidMaterialState(
    stageMaterialModel: PumpScheduleStageMaterialModel,
    fluidMaterialModel: FluidMaterialModel,
    baseCementMaterial: FluidMaterialModel,
    stageIndex: number,
    fluidModel: FluidModel,
    blendComponentsCount: number,
    stageCalc: StageFluidCalculator,
    stageModel: PumpScheduleStageModel
  ): FluidMaterialStateManager {

    return new FluidMaterialStateManager(
      stageMaterialModel,
      fluidMaterialModel,
      baseCementMaterial,
      this._materialMixProcedures$,
      this._materialMixDropdownItems$,
      stageIndex,
      fluidModel,
      blendComponentsCount,
      stageCalc,
      this._availableMaterialMapings$,
      this._viewState,
      this._materialService,
      this._unitConversionService,
      this._formManager,
      stageModel
    );
  }

  private _getListSchedule(
    job: Job,
    jobActionMode: JobActionMode,
    forControlPoint: boolean = false,
    dashboard = false): Observable<PumpSchedule[]> {
    if (forControlPoint) {

            return this.getControlPointSchedule(job);
        }
        const jobId = job.isClonedJob ? job.originalJobId : job.id;
        const isImport = jobActionMode
            && (jobActionMode === JobActionMode.CreateFromICem || jobActionMode === JobActionMode.CreateFromHDF);

    const importedSchedule = isImport ? job.pumpSchedules : null;

    if(dashboard) {
      return this._loadRequest$?.pipe(
        switchMapTo(
  
          importedSchedule
            ? of(importedSchedule)
            : jobId && jobId !== Guid.Empty
              ? this._pumpScheduleService.getPumpScheduleByJobIdForDashboard(jobId)?.pipe(
                map(x => job.isClonedJob ? x.map(item => this._initCopiedPumpSchedule(item)) : x),
              )
              : of(null)
        ),
        shareReplay()
      );
    }

    return this._loadRequest$?.pipe(
      switchMapTo(

        importedSchedule
          ? of(importedSchedule)
          : jobId && jobId !== Guid.Empty
            ? this._pumpScheduleService.getPumpScheduleByJobId(jobId)?.pipe(
              map(x => job.isClonedJob ? x.map(item => this._initCopiedPumpSchedule(item)) : x),
            )
            : of(null)
      ),
      shareReplay()
    );
  }

  public checkNameDuplicate(name: string, pumpScheduleId: string): Observable<boolean> {
    const listPumpDuplicate = this.listPumpScheduleStageStateManager.filter(item => item.displayText === name)
    if (listPumpDuplicate.length) {

      const body = { jobId: this._job.id, Name: name, pumpScheduleId }

      return !!pumpScheduleId ? this._pumpScheduleService.checkPumpScheduleDuplicate(body)
        .pipe(
          map(x => x)
        ) : of(true)
    } else {

      return of(false)
    }
  }

  public createPumpScheduleStageStateManager(
    pumpSchedule: PumpSchedule,
    index: number,
    job: Job,
    controlPointNumber: number,
  ): PumpScheduleStageStateManager {
    const pumpName = pumpSchedule.name || `Pump Schedule ${(index + 1) < 10 ? '0' : ''}${index + 1}`
    const defaultModel = { ...pumpSchedule, name: pumpSchedule.name || pumpName }

    const pumpManager = {
      displayText: pumpName,
      index: index,
      isHidden: index === 0 ? false : !job.isStageJob,
      scheduleState: null,
      model: defaultModel,
    }

    if (index === 0) {
      const scheduleState = this.createScheduleState(
        job,
        controlPointNumber,
        pumpName,
        'changed',
        pumpManager,
        true,
      )

      return {
        ...pumpManager,
        scheduleState
      }
    }

    const scheduleState = this.createHiddenScheduleState(
      job,
      controlPointNumber,
      pumpName,
      'changed',
      pumpManager,
      false
    )

    return {
      ...pumpManager,
      scheduleState
    }
  }

  public loadDataPumpSchedulesByCurrentJob(
    job: Job,
    jobActionMode: JobActionMode,
    controlPointNumber: number,
    callback?: any,
    dashboard?: boolean,
  ) {
    this._fluidService.resetYieldChangedWarningMessagesSource();
    this._getListSchedule(job, jobActionMode, !!controlPointNumber, dashboard)
      .pipe(take(1))
      .subscribe(data => {
        if (data) {
          this._fluidService.originFluids = cloneDeep(job.slurry);
          this.updateListPumpScheduleStageStateManager(
            data.map((model, index) => this.createPumpScheduleStageStateManager(model, index, job, controlPointNumber)));
          
          this._pumpSchedules$.next(data);
        }

        callback && callback(data);
      });
  }

  get controlPointAdapter() {
    return this._controlPointAdapter
  }

  get editJobAdapter() {
    return this._editJobAdapter
  }

  private _createStageTypeItems(stageTypes: IPumpScheduleFluidType[]): SelectItem<string>[] {

    const stageTypeItems = stageTypes.map(type => {

      const item: SelectItem<string> = {
        value: type.id,
        label: type.name
      };

      return item;
    });

    return stageTypeItems;
  }

  private _createPlacementMethodItems(placementMethods: IPlacementMethod[]): SelectItem<string>[] {

    const placementMethodItems = placementMethods.map(method => {

      const item: SelectItem<string> = {
        value: method.id,
        label: method.name,
        disabled: method.isDisable
      };

      return item;
    });

    return placementMethodItems;
  }

  private _createMaterialMixItems(procedures: MixingProcedure[]): SelectItem<string>[] {

    const procedureItems = procedures
      .sort((p1, p2) => {

        return PumpScheduleStateFactory._materialMixProceduresOrder[p1.id]
          - PumpScheduleStateFactory._materialMixProceduresOrder[p2.id];
      })
      .map(procedure => {

        const item: SelectItem<string> = {
          value: procedure.id,
          label: procedure.displayName || 'None (blank)',
          icon: procedure.value
        };

        return item;
      });

    return procedureItems;
  }

  public getControlPointSchedule(job: Job): Observable<PumpSchedule[]> {
    this.reload();
    const cpSchedule$ = this._loadRequest$?.pipe(
      switchMap(_ => this._controlPointAdapter.getJobMultiplePumpSchedule(job.id, job)),
      shareReplay()
    );

    // Also read fluids from new pump schedule and iFacts each time new pump schedule emitted
    this._subscriptions.add(
      cpSchedule$?.pipe(
        switchMap(pumpSchedules => {
          // This will make controlPointAdapter.fluids$ to emit pump schedule fluid list.
          return this._controlPointAdapter.getFluidForStage(job.id, job, pumpSchedules);
        }),
        tap(_ => this._controlPointAdapter.isReady$.next(true)),  // enables Save and Submit buttons on Control Point
        finalize(() => this._fluidService.publishYieldChangedWarningMessages()),
        shareReplay()
      )
        .subscribe()
    );

    return cpSchedule$;
  }
  private _initCopiedPumpSchedule(schedule: PumpSchedule): PumpSchedule {
    schedule.isForceNew = true;
    schedule.originalPumpScheduleId = schedule.pumpScheduleId;
    schedule.pumpScheduleId = guid();

    schedule.jobId = null;
    schedule.stages = schedule.stages.map((stage: PumpScheduleStageModel) => {

      const stageCopy = { ...stage };

      if (stageCopy.loadoutVolume != stage.plannedVolume){
        stageCopy.loadoutVolume = stage.plannedVolume;
        if (stage.plannedVolume != null)
          stageCopy.bulkCement = null; //will be recalculated
      }
      stageCopy.actualVolumePumped = stage.actualVolumePumped === null || stage.actualVolumePumped === undefined ? stage.plannedVolume : stage.actualVolumePumped;
      stageCopy.isChangeActualVolumePumped = true;
      stageCopy.isBulkCement = false;
      stageCopy.syncObjectiveId = stage.order;

      stageCopy.events = stageCopy.events.map(e => ({ ...e }));

      if (stageCopy.slurry) {

        const slurryCopy = { ...stage.slurry };

        stageCopy.fluidMaterials = stageCopy.fluidMaterials.map(sm => ({ ...sm, actualQty: null, overrideVolume: null }));

        slurryCopy.fluidMaterial = slurryCopy.fluidMaterial.map(fm => ({ ...fm }));

        stageCopy.slurry = slurryCopy;
      }

      return stageCopy;
    });

    return schedule;
  }

  private _clearModelIds(schedule: PumpSchedule): void {

    schedule.stages.forEach((stage: PumpScheduleStageModel) => {

      stage.id = Guid.Empty;
      stage.pumpScheduleId = Guid.Empty;

      stage.events.forEach(e => {
        e.pumpScheduleEventId = null;
        e.pumpScheduleStageId = null;
      });

      if (stage.slurry) {

        stage.slurry.id = null;
        stage.slurry.jobId = Guid.Empty;

        // Align stage materials with fluid materials
        // because on server they are assigned references to fluid materials
        // based just on their order.
        const orderedStageMaterials = [];
        stage.slurry.fluidMaterial.forEach(fm => {

          const found = stage.fluidMaterials.find(sm => (fm.id && sm.fluidMaterialId === fm.id) || (!fm.id && fm.materialId && sm.ifactMaterialId === fm.materialId && sm.materialType === fm.materialType));
          if (found) {

            found.id = null;
            found.pumpScheduleStageId = null;
            found.fluidMaterialId = null;

            orderedStageMaterials.push(found);
          }
        });

        stage.fluidMaterials = orderedStageMaterials;

        stage.slurry.fluidMaterial.forEach(fm => {
          fm.id = null;
          fm.slurryId = null;
        });
      }
    });
  }

  public get listControlEachPump() {
    return [
      'stages',
      'shoeTrackLength',
      'shoeTrackVolume',
      'targetSafetyFactor',
      'scheduledShutdown',
      'batchMixingTime',
    ]
  }

  public get listPumpScheduleGeneral() {
    return [
      'linerCementLength',
      'linerCirculationMethod',
      'isVersaFlexLiner',
      'spacerMixMethod'
    ]
  }

  public makePropertiesGeneral(type?: 'addNew' | 'changed') {

    const list = this.listPumpScheduleStageStateManager
    const currentModel = type === 'addNew' ? list[0].model : this.getModel();

    const changedList = list.map((item: PumpScheduleStageStateManager) => {
      const model: PumpSchedule = { ...item.model }
      this.listPumpScheduleGeneral.map(generalField => {
        //If any field is changing then update data in model
        if (currentModel && model && currentModel[generalField] !== undefined && currentModel[generalField] != model[generalField]) {
          model[generalField] = currentModel[generalField]
        }
      })
      return {
        ...item,
        model
      }
    })

    this.updateListPumpScheduleStageStateManager(changedList);
  }

    private _setIdsForCreatedStagesAndMaterials(): void {
        if (this._pumpScheduleReloadSubscription) {
            this._pumpScheduleReloadSubscription.unsubscribe();
        }
        if(this._job.id) {
            this._pumpScheduleReloadSubscription = this._pumpScheduleService.getPumpScheduleByJobId(this._job.id)
            .subscribe(savedSchedules => {
                savedSchedules.map(
                    savedSchedule => {

            // set order of stage material models the same as fluid materials order
            savedSchedule.stages.forEach(stage => {

              stage.fluidMaterials.forEach(stageMaterial => {

                stageMaterial.order = stage.slurry.fluidMaterial.find(fm => fm.id === stageMaterial.fluidMaterialId).order;
              });
            });

            // do not update pump schedule cache in getModel(), will be updated after material id are set (see below).
            const inMemorySchedule = this.getModel(false, false);

            inMemorySchedule.stages.forEach((stage, i) => {

              const savedStage = savedSchedule.stages[i];

              if (!stage.id) {

                stage.id = savedStage.id;
              }

              stage.fluidMaterials.forEach(stageMaterial => {

                if (!stageMaterial.id) {

                  const foundSavedMaterial = savedStage.fluidMaterials.find(saved => saved.order === stageMaterial.order);

                  stageMaterial.id = foundSavedMaterial.id;
                  stageMaterial.fluidMaterialId = foundSavedMaterial.fluidMaterialId;
                  stageMaterial.fluidMaterialName = foundSavedMaterial.fluidMaterialName;
                }
              });
            });

                        this._applicationStateService.pumpScheduleCache$.next(inMemorySchedule);
                    }
                )
            }, error => {

            });
        }

    }

    public getFluidStateManagerListByFluid$(requestId: number, slurryNo: number): Observable<FluidStateManager[]> {
      return this.listPumpScheduleStageStateManager$.pipe(
        map(x => x.reduce((acc: FluidStateManager[], ps: PumpScheduleStageStateManager) => {
          ps?.scheduleState?._stagesStatesSrc?.value?.forEach((s: StageStateManager) => {
            if (s?.fluid?.requestId === requestId && s?.fluid?.slurryNo === slurryNo) {
              acc.push(s.fluidState);
            }
          })
  
          return acc;
        }, [] as FluidStateManager[]))
      )
    }

    public updateFluidInAllStages(requestId: number, slurryNo: number, fluid: FluidModel) {
      this.getFluidStateManagerListByFluid$(requestId, slurryNo)
        .pipe(
          first()
        )
        .subscribe(fluidStateManagerList => {
          fluidStateManagerList.forEach(s => s.updateFluidModel(fluid))
        });
    }
}
