<div>
  <div class="d-flex pt-4">
    <div class="form-subtitle-section">{{TestTableName.Rheology}}</div>
    <div class="p-2 pl-5" style="font-weight: bold;">Slurry #</div>
    <div style="width: 60px;">
      <p-dropdown [formControl]="apiRheologySelectedSlurry"
                  (onChange)="onChangeSlurryNo($event, TestTableName.Rheology)"
                  [options]="availableAPIRheologySlurries"
                  [disabled]="!canEdit"
                  appendTo="body"
                  [placeholder]="'\u00A0'"
                  baseZIndex="9999">
      </p-dropdown>
    </div>
  </div>

  <div class="ui-table">
    <table style="font-weight: bold;">
      <thead>
        <tr>
          <th style="width: 100px;">Temp.</th>
          <th>600<br>(rpm)</th>
          <th>300<br>(rpm)</th>
          <th>200<br>(rpm)</th>
          <th>100<br>(rpm)</th>
          <th>60<br>(rpm)</th>
          <th>30<br>(rpm)</th>
          <th>20<br>(rpm)</th>
          <th>10<br>(rpm)</th>
          <th>6<br>(rpm)</th>
          <th>3<br>(rpm)</th>
          <th>BR<br>Conf</th>
          <th>
            <div class="d-flex flex-row">
              <div>PV</div>
              <div class="ml-3">
                <more-info-tooltip>
                  <i icon class="fa fa-info-circle" style="color: black;"></i>
                  <div message>Calculation Method: Traditional (300 & 100 RPM based)</div>
                </more-info-tooltip>
              </div>
            </div>
            <div class="d-flex flex-row">(cP)</div>
          </th>
          <th style="width: 90px;">
            <div class="d-flex flex-row">
              <div>Yp</div>
              <div class="ml-3">
                <more-info-tooltip>
                  <i icon class="fa fa-info-circle" style="color: black;"></i>
                  <div message>Calculation Method: Traditional (300 & 100 RPM based)</div>
                </more-info-tooltip>
              </div>
            </div>
            <div class="d-flex flex-row">(lbs/100ft2)</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let test of getTests(TestTableName.Rheology)" class="test-row">

          <td>
            <span [apiValue]="getTemperatureField(test)"
                  [formatNum]="1"
                  [shouldShowFormatNum]="true"
                  [unitType]="UnitType.Temperature">
            </span>
          </td>

          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '600')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '300')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '200')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '100')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '60')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '30')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '20')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '10')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '6')}}</td>
          <td *ngIf="isTestFinishedOrValidated(test)">{{getField(test, '3')}}</td>

          <td *ngIf="!isTestFinishedOrValidated(test)" colspan="10">{{test.testStatusDescription}}</td>

          <td>{{getField(test, 'B-R Conf.')}}</td>
          <td>{{getField(test, 'PVTraditional')}}</td>
          <td class="relative">{{getField(test, 'YPTraditional')}}
            <button *ngIf="canEdit" class="btn-delete-test" (click)="deleteTest(test)">
              <div class="btn-delete-test-icon">
                <i class="fa fa-times"></i>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
