import { PumpScheduleEventModel } from './pump-schedule-event';
import { DeadVolumeFluidType } from 'libs/constants';
import { MudParameterModel } from '../mud-parameter';
import { FluidModel } from '../fluids';
import { MixingProcedure } from '../ifact/ifacts-material';
import { Guid } from 'apps/vida/src/modules/shared/constant';
import { PumpScheduleStageTestTable } from '../ifact/ifacts-request-tests';

export class PumpScheduleStageModel {
  id: string = null;
  pumpScheduleId: string = Guid.Empty;
  order: number = 0;
  syncObjectiveId: number = null;
  number: number = 0;
  pumpScheduleFluidTypeId: string = null;
  pumpScheduleFluidTypeName: string = null;
  slurry: FluidModel = null;
  actualSlurry: FluidModel = null;
  avgRate: number = null;
  topOfFluid: number = 0;
  mudParameter: MudParameterModel = new MudParameterModel();
  events: PumpScheduleEventModel[] = [];
  selectedFluidId: string = null;
  comment: string = null;
  isExpanded: boolean = false;
  isExpandedEvent: boolean = false;
  isExpandedFluid: boolean = false;
  isMudType: boolean = false;
  isManualyThickeningTime: boolean = false;
  isChangeActualVolumePumped: boolean = false;
  placementTime: number = null;
  plannedVolume: number = null;
  loadoutVolume: number = null;
  isChangeLoadOutVolume: boolean = false;
  bulkCement: number = null;
  isBulkCement: boolean = false;
  thickeningTime: string = null;
  minThickeningTime: string = null;
  actualSafety: string = null;
  fluidMaterials: PumpScheduleStageMaterialModel[] = [];
  pumpScheduleStageTestTables: PumpScheduleStageTestTable[] = [];
  slurryIdHDF: string = null;
  actualVolumePumped: number = null;
  dryWeight: number = null;
  dryVolume: number = null;
  plannedDensity: number = null;
  actualDensity: number = null;
  deadVolume: number = null;
  deadVolumeFluidType: DeadVolumeFluidType = DeadVolumeFluidType.CementSlurry;
  isManuallyDeadVolume: boolean = false;
  stageWaterTotal: number = null;
  waterRequirements: number = null;
  orderDisplay: number = null;
  slurryTypeId: string = null;
  totalCOGS: number = null;
  isRemoveable: boolean = false;
  pumpOrder: number = 0;
  totalBlendCO2e: number = 0;
  totalBlendActualCO2e: number = 0;
}

export class PumpScheduleStageMaterialModel {

  public id: string = null;
  public pumpScheduleStageId: string = null;
  public order: number = 0;
  public cemBlendCompId: string = null;
  public fluidMaterialId: string = null;
  public fluidMaterialName: string = null;
  public ifactMaterialId: string = null;
  public concentration: number = null;
  public concentrationUnit: string = null;
  public plannedVolumeUnit: number = null;
  public loadoutVolume: number = null;
  public loadoutVolumeUnit: number = null;
  public overrideVolume: number = null;
  public plannedQty: number = null;
  public actualQty: number = null;
  public isChangeActualQty: boolean = false;
  public sapMaterialNumber: string = null;
  public sapMaterialName: string = null;
  public sapMaterialDisplayName: string = null;
  public dryVolume: number = null;
  public totalCOGS: number = null;
  public overrideMixingProcedureId: string;
  public overrideMixingProcedure: MixingProcedure;
  public mixingProcedureId: string = null;
  public mixingProcedureValue: string = null;
  public materialType:string;
  public plannedScope3Co2e: number = null;
  public actualScope3Co2e: number = null; 
}

export class PumpScheduleStageCustom {
  id: string;
  slurryId: string;
  order: number;
  stageOrder: number;
  pumpScheduleFluidTypeName: string;
  thickeningTime: string;
  displayName: string;
  disabled = false;
}
