import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, of, timer, interval } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, retryWhen, switchMap, take, filter, takeUntil, tap } from 'rxjs/operators';

import { environment } from 'libs/environment';
import { Job, ChildParentJobModel, ObjectiveModel, FluidModel, Lesson, AdUser } from 'libs/models';
import { Role } from 'apps/vida/src/modules/application/models';
import { IAdditionalParams, ISearchResultsReport } from 'apps/vida/src/modules/shared/models';
import { HttpService } from './http.service';
import { ConfirmationDialogService } from '../../ui/services'
import { MINIMUM_FILL_SO_TIME } from '../../constants/sale-order-number.constants';
import { JobLogSummaryEvents } from 'libs/models/entities/job-log';
import { JOB_LOG } from 'libs/constants';
import { RiskAssessment } from 'apps/vida/src/modules/control-point/models';
import { EventHubService } from './event-hub.service';
import { CP3_EXPORT_SPREADSHEET_NAME } from 'apps/vida/src/modules/shared/constant';
import { JobState } from 'libs/models/entities/job-state';
import { MessageService } from 'primeng/api';

export class ReportOptions {
  filename = CP3_EXPORT_SPREADSHEET_NAME;
  reduced: boolean; //remove fields for USER STORY 424504
}

@Injectable()
export class JobsService {
  data = [];
  baseURL = environment.baseUrl;
  status = 0;
  groupId = 0;
  showAlertMessage$ = new BehaviorSubject<boolean>(false);
  groupId$ = new BehaviorSubject<string>(null);
  statusText = 'All';
  private _lastCreatedJobId$ = new BehaviorSubject<string>(null)
  private isIcemJobUpdate = new BehaviorSubject(null);
  private isjobCreatedFromIcem = new BehaviorSubject(null);
  private readonly _DENSITY_TO_PRESSURE_FACTOR = 0.05195;
  sharedisIcemJobUpdate = this.isIcemJobUpdate.asObservable();
  sharedJobCreatedFromIcem = this.isjobCreatedFromIcem.asObservable();
  constructor(
    private httpService: HttpService,
    private confirmationDialogService: ConfirmationDialogService,
    private eventHubService: EventHubService,
    private messageService: MessageService) {
  }

  confirmSAPChangeBeforeSavingData(saveFunc: Function) {
    this.confirmationDialogService.confirm({
      header: 'SAP Material Change',
      message: 'The Control Point 2 is completed. Changing the SAP material now will not propagate to any other system. Would you like to proceed?',
      commentFieldTitle: 'Comments/Justification',
      commentFieldRequired: false,
      accept: (comment) => {
        saveFunc();
      }
    });
  }

  saveBA(jobId: string, data: any) {
    this.httpService.Put(`${environment.baseUrl}/api/jobs/ba/${jobId}`, JSON.stringify(data)).subscribe(_ => {});
  }

  basicSearchAll(additionalParams: IAdditionalParams): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.httpService.Get(`${environment.baseUrl}/api/jobs/basic-search${params}`);
  }

  searchJobByGroup(jobId: string, groupId: string, additionalParams: IAdditionalParams): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.httpService.Get(`${environment.baseUrl}/api/groups/${groupId}/jobs/search-criteria/${jobId}${params}`);
  }

  advanceSearch(query): Observable<any> {
    return this.httpService.Post(`${environment.baseUrl}/api/jobs/search/`, JSON.stringify(query));
  }

  getJobByJobId(jobId: string, includeControlPoints: boolean = false): Observable<Job> {
    return this.httpService.Get<Job>(`${environment.baseUrl}/api/jobs/${jobId}?includeControlPoints=${includeControlPoints}`);
  }

  getJobForEditing(jobId: string): Observable<Job> {

    const httpGetMethod$ = this.httpService.Get<Job>(`${environment.baseUrl}/api/jobs/${jobId}/editing`);

    return this.checkAndCalcIfactsData(jobId)
      .pipe(switchMap(_ => httpGetMethod$));
  }

  checkAndCalcIfactsData(jobId: string): Observable<unknown> {
    return this.httpService.Patch<object>(`${environment.baseUrl}/api/jobs/${jobId}/check-and-calc-ifacts-data`, null);
  }

  updateEditJob(jobId: string, obj: any) {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}`, JSON.stringify(obj));
  }

  getBOMTypes() {
    return this.httpService.Get(`${environment.baseUrl}/api/jobsettings/BOMTypes/status/${this.statusText}`);
  }

  getJobTypes() {
    return this.httpService.Get(`${environment.baseUrl}/api/jobsettings/JobTypes/status/${this.statusText}`);
  }

  getWells(grp: any) {
    return this.httpService.Get(`${environment.baseUrl}/api/wells?groupId=${grp}`);
  }

  getDeviationStatus(value = this.statusText) {
    return this.httpService.Get(`${environment.baseUrl}/api/jobsettings/DeviationStatus/status/${value}`);
  }

  getGroups() {
    return this.httpService.Get(`${environment.baseUrl}/api/admin/groups`);
  }

  getJobStatus() {
    return this.httpService.Get(`${environment.baseUrl}/api/jobsettings/JobStatus/status/${this.statusText}`);
  }

  getRigs() {
    return this.httpService.Get(`${environment.baseUrl}/api/rigs?status=${this.status}`);
  }

  createJob(job: any) {
    return this.httpService.Post<string>(`${environment.baseUrl}/api/jobs`, job)
      .pipe(
        map(jobId => {
          this._lastCreatedJobId$.next(jobId);
          return jobId
        })
      );
  }

  getSavedJobsByUserId(additionalParams: IAdditionalParams, groupId?: string, keyword = ''): Observable<any> {

    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}&keyword=${keyword}`;

    return this.httpService.Get(`${environment.baseUrl}/api/jobs/saved-jobs/${groupId}${params}`);
  }

  saveJobAuthenticated(job) {
    return this.httpService.Post(`${environment.baseUrl}/api/jobs`, JSON.stringify(job));
  }

  removeJobFromSavedJobs(jobId) {
    return this.httpService.Delete(`${environment.baseUrl}/api/jobs-library/${jobId}`);
  }

  saveToLibrary(jobNumber) {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs-library/${jobNumber}`, JSON.stringify(jobNumber));
  }

  getCustomerByName(customerNameSearched: any) {
    return this.httpService.Get(`${environment.baseUrl}/api/customers/${customerNameSearched}`);
  }

  updateDate(jobId, projectedDate) {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}/${projectedDate}`, projectedDate);
  }

  getMyJobs(additionalParams: IAdditionalParams, groupId?: string, keyword = ''): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}&keyword=${keyword}`;

    return this.httpService.Get(`${environment.baseUrl}/api/jobs/my-jobs/${groupId}${params}`);
  }

  getActiveJobsByCurrentLocation(groupId: any, additionalParams: IAdditionalParams, keyword = ''): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}&keyword=${keyword}`;

    return this.httpService.Get(`${environment.baseUrl}/api/jobs/active-jobs/${groupId}${params}`);
  }

  GetActiveJobByMemberOfGroups(additionalParams: IAdditionalParams, keyword = ''): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;

    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}&keyword=${keyword}`;
    return this.httpService.Get(`${environment.baseUrl}/api/jobs/active-jobs/group-member${params}`);
  }

  getUserRoleByGroupIdAndJobId(groupId, jobId) {
    return this.httpService.Get<Role[]>(`${environment.baseUrl}/api/admin/roles/${groupId}/${jobId}`);
  }

  updateProjectedDate(jobId: string, projectedDate: Date): Observable<boolean> {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}/projected-date`, JSON.stringify(projectedDate));
  }

  updateActualDate(jobId: string, actualDate: Date): Observable<boolean> {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}/actual-date`, JSON.stringify(actualDate));
  }

  updateFromSalesOrder(jobId: string, soInfo: any): Observable<boolean> {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}/update-so`, JSON.stringify(soInfo));
  }

  isOverdue(projectedDate: string): boolean {
    if (projectedDate) {
      const diffMiliSeconds = (new Date(projectedDate)).getTime() - (new Date()).getTime();
      return diffMiliSeconds < 3 * 24 * 60 * 60 * 1000;
    }

    return false;
  }

  getJobsByWell(wellId: string, additionalParams: IAdditionalParams): Observable<any> {
    const { sortField, sortOrder, pageSize, pageNum } = additionalParams;
    const params = `?sortField=${sortField}&sortOrder=${sortOrder}&pageSize=${pageSize}&pageNum=${pageNum}`;

    return this.httpService.Get(`${environment.baseUrl}/api/jobs/well/${wellId}${params}`);
  }

  UpdateJobInfo(jobId: string, cpJobInfoModel: any): Observable<any> {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/${jobId}/update-info`, JSON.stringify(cpJobInfoModel));
  }

  saleOrderValueChange(control) {
    return control.valueChanges
      .pipe(
        debounceTime(MINIMUM_FILL_SO_TIME),
        distinctUntilChanged()
      );
  }

  public getSpreadSheet(jobId: string, options: ReportOptions): Subscription {
    return this.getSpreadsheetDownloadUrl(jobId, options).subscribe(url => {
      this.sheetDownload(url, options.filename).then(async x => {
        if (x.status == 500) {
          const errorText = await x.response.text();
          const errorList = errorText.split(';');
          this.messageService.add({
            life: environment.messagePopupLifetimeMs,
            severity: 'error',
            detail: `Can't generate a report due to some reasons:<br>${errorList.join("<br>")}`
          });
        }
        this.eventHubService.onCp3SpredSheetDownload$.next(true);
      })
    });
  }

  public getBasicCheckSheets(jobId: string): void {
    let fileName = jobId + ".zip";
      this.getCEBasicSpreadsheetDownloadUrl(jobId).subscribe(url => {
        this.sheetDownload(url, fileName).then(x => {
      })
    });
  }

  private getSpreadsheetDownloadUrl(jobId: string, options?: ReportOptions): Observable<string> {

    if (options && options.reduced) {
      return this.httpService
      .Get<{ url: string }>(`${environment.baseUrl}/api/report/${jobId}/jsqa-spreadsheet-reduced`)
      .pipe(
        map(result => result.url)
      );
    }

    return this.httpService
      .Get<{ url: string }>(`${environment.baseUrl}/api/report/${jobId}/jsqa-spreadsheet`)
      .pipe(
        map(result => result.url)
      );
  }


  private getCEBasicSpreadsheetDownloadUrl(jobId: string): Observable<string> {
    return this.httpService
      .Get<{ url: string }>(`${environment.baseUrl}/api/report/${jobId}/CEChecksheetsUrl`)
      .pipe(
        map(result => result.url)
      );
  }

  public getLoadSheetCalculator(jobId: string): void {
    this.getLoadSheetCalculatorDownloadUrl(jobId)
      .subscribe(({ url, fileName }) => this.sheetDownload(url, fileName));
  }

  private getLoadSheetCalculatorDownloadUrl(jobId: string): Observable<any> {
    return this.httpService.Get<{ url: string, fileName: string }>(`${environment.baseUrl}/api/report/${jobId}/loadsheet-calculator?timezoneOffset=${new Date().getTimezoneOffset()}`);
  }

  private sheetDownload(url: string, fileName: string): Promise<any> {
    var res = Promise.resolve();
    res = res.then(function () {
      return new Promise(function (resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
          resolve(xhr);
        };
        xhr.onerror = reject;
        xhr.open('GET', url);
        xhr.send();
      }).then(function (xhr: any) {
        if (xhr.status == 500)
          return xhr;

        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        return xhr;
      });
    });

    return res;
  }

  public callSheetDownload(url: string, fileName: string): void {
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public materialLoadSheetDownload(url: string, fileName: string): void {
    var a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  public getRiskAssessmentFileForCp3(riskAssessment: RiskAssessment): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = riskAssessment.filePathDownload;
    a.download = riskAssessment.getFileName();
    a.click();
    window.URL.revokeObjectURL(riskAssessment.filePathDownload);
    a.remove();
  }

  public getSearchResultsReportsSettings(): Observable<ISearchResultsReport[]> {

    return this.httpService.Get<ISearchResultsReport[]>(`${environment.baseUrl}/api/job-search-report/settings`);
  }

  generateBasicReport(fileName: string, paramsForSearch: any): Observable<any> {
    const body = {
      reportName: fileName,
      searchParameters: paramsForSearch,
      timezoneOffset: new Date().getTimezoneOffset()
    };

    return this.httpService.Post<any>(`${environment.baseUrl}/api/job-search-report/basic`, body).pipe(
      switchMap((actionId: any) => this.waitForComplete(actionId))
    );
  }

    generateCERunHistoryReport(fileName: string, paramsForSearch: any): Observable<any> {
    const body = {
      reportName: fileName,
      searchParameters: paramsForSearch,
      timezoneOffset: new Date().getTimezoneOffset()
    };

    return this.httpService.Post<any>(`${environment.baseUrl}/api/job-search-report/cerunhistory`, body).pipe(
      switchMap((actionId: any) => this.waitForComplete(actionId))
    );
  }

  generateExpandedReport(fileName: string, paramsForSearch: any): Observable<any> {
    const body = {
      reportName: fileName,
      searchParameters: paramsForSearch,
      timezoneOffset: new Date().getTimezoneOffset()
    };

    return this.httpService.Post<any>(`${environment.baseUrl}/api/job-search-report/expanded`, body).pipe(
      switchMap((actionId: any) => this.waitForComplete(actionId))
    );
  }

  generateFinancialReport(fileName: string, paramsForSearch: any): Observable<any> {
    const body = {
      reportName: fileName,
      searchParameters: paramsForSearch,
      timezoneOffset: new Date().getTimezoneOffset()
    };

    return this.httpService.Post<any>(`${environment.baseUrl}/api/job-search-report/financial`, body).pipe(
      switchMap((actionId: any) => this.waitForComplete(actionId))
    );
  }

  generateFinancialStageReport(fileName: string, paramsForSearch: any): Observable<any> {
    const body = {
      reportName: fileName,
      searchParameters: paramsForSearch
    };

    return this.httpService.Post<any>(`${environment.baseUrl}/api/job-search-report/financialstage`, body).pipe(
      switchMap((actionId: any) => this.waitForComplete(actionId))
    );
  }

  private waitForComplete(actionId: string): Observable<any>{
    const checkStatusFiltered$: Observable<BackgroundTaskStatusDetails> = this.checkReportStatus(actionId).pipe(
      filter((details: BackgroundTaskStatusDetails) => {
        const status = details.status;
        return status == 'done' 
              || status == 'error' 
              || status == 'canceled'
              || status == 'not found';
      }),
      map((details: BackgroundTaskStatusDetails) => details.result)
    );

    return interval(1500).pipe(
      switchMap(() => 
        checkStatusFiltered$.pipe(
          tap((res) => {
            if (res.status == 'error') {
              throw res.message;
            }
            else if (res.status == 'cancelled') {
              throw 'Report generation was cancelled';
            }
            else if (res.status == 'not found') {
              throw 'Report was not found'
            }
            else if (res.status == 'done' && res.result == null){
              throw 'Report generation error'
            }
          })
        )
      ),
      take(1)
    );
  }

  private checkReportStatus(actionId: string): Observable<BackgroundTaskStatusDetails>{
    return this.httpService.Get<any>(`${environment.baseUrl}/api/job-search-report/checkstatus?actionid=${actionId}`)
    .pipe(
      map((res) => {
        return res== null ? {status: 'not found', message: null, result: null } : res;
      })
    );
  }

  getInfoOriginJob(groupId: string, jobCode: string): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/jobs/origin-job-info/${groupId}/${jobCode}`);
  }

  updateJobLink(jobCode: string, orginalJobId: string, orginalJobCode: string): Observable<any> {
    const hostUrl = environment.azureAd.redirectUri;
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/link-job/${jobCode}`, JSON.stringify({ orginalJobId, orginalJobCode, hostUrl }));
  }

  removeJobLink(model: ChildParentJobModel): Observable<boolean> {
    model.hostUrl = environment.azureAd.redirectUri;
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/unlink-job`, JSON.stringify(model));
  }

  checkIsPlugJob(value: string): boolean {
    return value && (value.toLowerCase().indexOf('plug') >= 0 || value.toLowerCase().indexOf('kop') >= 0);
  }

  checkIsExistsHDFJobId(hdfJobId: string): Observable<any> {
    return this.httpService.Get(`${environment.baseUrl}/api/hdf/is-exists-hdf-job-id/${hdfJobId}`);
  }

  getControlPointJobOriginalStatus(jobId: string): Observable<boolean> {
    return this.httpService.Get(`${environment.baseUrl}/api/jobs/${jobId}/control-point-job-original-status`);
  }


  getJobLogSummaryEvents(jobId: string): Observable<JobLogSummaryEvents> {
    return this.httpService.Get<JobLogSummaryEvents>(JOB_LOG.SUMMARY(jobId));
  }

  getJobObjectives(jobId: string): Observable<ObjectiveModel> {
    return this.httpService.Get<ObjectiveModel>(`${environment.baseUrl}/api/jobs/job-objectives/${jobId}`);
  }

  getSlurryList(jobId: string): Observable<FluidModel[]> {
    return this.httpService.Get<FluidModel[]>(`${environment.baseUrl}/api/jobs/${jobId}/slurry-list`);
  }

  getAppianJobLink(jobId: string): Observable<string> {
    return this._lastCreatedJobId$
        .pipe(
          switchMap(lastJobId => lastJobId === jobId ? timer(10000) : of({})),
          switchMap(() => this.httpService.Get<string[]>(`${environment.baseUrl}/api/jobs/${jobId}/appian-link`)),
          map((response: string[]) => {if (response[0] == null) throw new Error('Appian link not found'); else return response[0]}),
          retryWhen(errors => errors.pipe(delay(10000))),
          take(1),
          map(linksArr => linksArr),
        )
  }

  getJobState(jobId: string): Observable<JobState> {
    return this.httpService.Get<JobState>(`${environment.baseUrl}/api/jobs/${jobId}/status`)
  }

  getLessonsList(jobId: string): Observable<Lesson[]> {
    return this.httpService.Get<Lesson[]>(`${environment.baseUrl}/api/jobs/${jobId}/lessons`);
  }

  getLessonsLearnedUrl(): Observable<string> {
    return this.httpService.Get<string>(`${environment.baseUrl}/api/jobs/lessons-learned-url`);
  }

  createLesson(jobCode: string): Observable<boolean> {
    return this.httpService.Post<boolean>(`${environment.baseUrl}/api/jobs/${jobCode}/lesson`, null);
  }

  public pressureZoneDensityToPressure(density: number, tvd: number): number | null {

    // in case we got strings instead of numbers
    density = Number(density);
    tvd = Number(tvd);

    if (!density || !tvd) {
      return null;
    }

    return density * tvd * this._DENSITY_TO_PRESSURE_FACTOR;
  }

  public pressureZonePressureToDensity(pressure: number, tvd: number): number | null {

    // in case we got strings instead of numbers
    pressure = Number(pressure);
    tvd = Number(tvd);

    if (!pressure || !tvd) {
      return null;
    }

    return pressure / (tvd * this._DENSITY_TO_PRESSURE_FACTOR);
  }

  public getUsersToSetAsJobOwner(jobId: string, keyword: string): Observable<AdUser[]> {
    return this.httpService.Get<AdUser[]>(`${environment.baseUrl}/api/jobs/job-owners?jobId=${jobId}&keyword=${encodeURIComponent(keyword)}`);
  }

  public changeJobOwner(jobId: string, user: AdUser): Observable<boolean> {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/change-job-owner?jobId=${jobId}`, JSON.stringify(user));
  }

  icemJobUpdated(isIcem) {
    this.isIcemJobUpdate.next(isIcem);
  }

  jobCreatedFromIcem(isIcem) {
    this.isjobCreatedFromIcem.next(isIcem);
  }

  public getJobForDashboardById(jobId: string): Observable<Job> {
    return this.httpService.Get<Job>(`${environment.baseUrl}/api/jobs/${jobId}/dashboard`);
  }

  updateJobModifiedDate(jobId: string): Observable<any>  {
    return this.httpService.Put(`${environment.baseUrl}/api/jobs/update-modified-date/${jobId}`, null);
  }

  public getJobLessonsByJobId(jobId: string): Observable<any[]> {
    return this.httpService.Get<any[]>(`${environment.baseUrl}/api/jobs/${jobId}/job-lessons`)
  }
}

export class BackgroundTaskStatusDetails {
  status: string;
  message: string;
  result: any;
}