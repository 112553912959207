import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Job } from 'libs/models';

export interface ISalesOrderSaveSucces {
  success: boolean;
}

export function intstanceOfISalesOrderSaveSucces (value: any): boolean {
  return typeof value !== 'boolean' && 'success' in value;
}

@Injectable()
export class JobModalManagementService {
  action$ = new BehaviorSubject<JobCreationAction>(null);

  $cancelJob = new Subject();
  hideModal$ = new Subject<boolean>();
  afterhideModal$ = new Subject<boolean>();
  $showConfirmModal = new Subject<boolean>();
  jobEditSaveButtonWasPushed$ = new Subject<boolean>();
  jobEditSaveButtonWasClicked$ = new BehaviorSubject<boolean>(false);

  $saveSuccess = new Subject<string[]>();
  $saveSuccessJobId = new Subject<string>();
  $getPumpScheduleWhenSaveSuccess = new Subject<any>();
  $saveError = new Subject<boolean>();
  nextSuccess$ = new Subject<boolean>();
  $nextError = new Subject<boolean>();
  $changeGroup = new Subject<any>();
  $ifactSearch = new Subject();
  $createSuccessFromHDF = new Subject<Job>();
  $moveICemFilesSuccess = new Subject<boolean>();
  $createJobSuccess = new Subject<string>();
  $cancelDataJobObjective = new Subject<any>();
  $cancelDataPumpSchedule = new Subject<any>();
  $foamJobFluid = new Subject<boolean>();
  $reloadCP1CheckedIcon = new Subject<boolean>();
  $jobOffshoreUpdated = new Subject<boolean>();
  $jobAppianPushStatus= new Subject<number>();
}

export const enum JobCreationActionName {
  ModalInit,
  ModalAfterInit,
  GroupChanged
}

export class JobCreationAction {
  constructor(
    public type: JobCreationActionName,
    public payload?: any
  ) {
  }
}

