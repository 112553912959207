import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ERROR_TYPE, UnitType } from 'libs/constants';
import { FluidModel, Job } from 'libs/models';
import { ApplicationStateService, ControlPointService2 } from 'libs/shared/services';
import { ErrorMessageModel, DynamicComponentService } from 'libs/ui';
import { getDisplayValue, UnitConversionService } from 'libs/ui/unit-conversions';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BehaviorSubject, combineLatest, of, Observable, Subscription } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';
import { ActualQuantityConfirmationComponent } from '../../../sidebar-dialogs/components';
import { ActualQuantityConfirmationService } from '../../../sidebar-dialogs/services';
import { StageStateManager } from '../../state/stage-state-manager';
import { ViewState } from '../../view-state';
import { isPrimitive } from 'libs/helpers/type.helper';
import { PumpScheduleStateFactory } from '../../state/state-factory';
import { PumpScheduleStageStateManager } from '../../models/pump-schedule-stage-state-manager.model';
import { MudParametersComponent } from '../../../pump-schedule/components';
import { ControlPointState } from '../../../shared/constant';

@Component({
  selector: 'stage-info',
  templateUrl: './stage-info.component.html',
  styleUrls: ['./stage-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StageInfoComponent implements OnInit, OnDestroy {
  private weight;
  private volume;
  private static _cp1Fields: string[] = [
    'avgRate',
    'volume',
    'topOfFluid',
    'fluidName',
    'totalCOGS'
  ];

  private static _cp2Fields: string[] = [
    'avgRate',
    'volume',
    'topOfFluid',
    'dryWeight',
    'dryVolume',
    'fluidName'
  ];

  private static _cp4Fields: string[] = [
    'volume',
    'loadOutQuantity',
    'actualVolumePumped',
    'plannedDensity',
    'actualDensity'
  ];

  private static _scheduleEditFields: string[] = [
    ...StageInfoComponent._cp2Fields,
    'totalCOGS'
  ];

  // array index corresponds to cp number,
  // if 0 - this is schedule edit view
  private static _visibleFields: string[][] = [
    StageInfoComponent._scheduleEditFields,
    StageInfoComponent._cp1Fields,
    StageInfoComponent._cp2Fields,
    [],
    StageInfoComponent._cp4Fields
  ];

  private _filteredFluids$: Observable<FluidModel[]>;

  private readonly _subscriptions = new Subscription();

  private readonly _fluidSearchCompleteSrc = new BehaviorSubject<string>(null);
  @Input() isRequiredShow;
  @Input()
  public stageState: StageStateManager;

  @Input() public isPumpDefault: boolean;

  @Input() public job: Job;
  @Input() jobType: string;

  @Input() public hasScope3access: boolean;

  public UnitType = UnitType;

  public loadingSubscription: Subscription;

  public name: string;


  public pumps: PumpScheduleStageStateManager[] = [];

  public errorMessages = {
    pumpScheduleFluidType: [
      new ErrorMessageModel(ERROR_TYPE.REQUIRED, 'Type is required field.')
    ],
    pumpScheduleLinkedFluid: [
      new ErrorMessageModel(ERROR_TYPE.INVALID_VALUE, 'Invalid Fluid')
    ]
  };

  public readonly isFluidEditDisabled$: Observable<boolean> =
    this._applicationStateService.notifyIFactDown$
      .pipe(
        map(isDown => {
          return isDown || !this.isJobEditable;
        }),
        shareReplay()
      );

  constructor(
    private readonly _applicationStateService: ApplicationStateService,
    private readonly _changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private actualQuantityConfirmationService: ActualQuantityConfirmationService,
    private unitConversionService: UnitConversionService,
    public dynamicComponentService: DynamicComponentService,
    private readonly _scheduleStateFactory: PumpScheduleStateFactory,
    private controlPointService: ControlPointService2
  ) { }

  public get _viewState(): ViewState {

    return this.stageState.viewState;
  }

  public get stageInfoForm(): UntypedFormGroup {
    if (this.jobType == 'ceServices') {
      this.stageState.form.get('actualDensity').setErrors(null);
      this.stageState.form.get('actualDensity').setValidators(null);
    }

    return this.stageState.form;
  }

  public get fluidName() {
    let data = this.stageState.form.get('fluidName').value;
    return data;
  }

  public get isScheduleEditView(): boolean {

    return this._viewState.isScheduleEditView;
  }

  public get isJobEditable(): boolean {

    return this._viewState.isJobEditable;
  }

  public isVisible$(field: string): Observable<boolean> {

    return this._viewState.isFieldVisible$(
      field,
      StageInfoComponent._visibleFields
    );
  }

  public get isMud$(): Observable<boolean> {

    return this.stageState.isMud$;
  }

  public get isNotPlug$(): Observable<boolean> {

    return this.stageState.isPlug$
      .pipe(
        map(isPlug => {
          return !isPlug;
        })
      );
  }

  public get isFluid$(): Observable<boolean> {

    return this.stageState.isFluid$;
  }

  public get isIFactsFluid$(): Observable<boolean> {

    return this.stageState.isIFactsFluid$;
  }

  public get isCementOrSpacer$(): Observable<boolean> {
    return this.typeName$.pipe(map(name => name == 'Cement' || 
      name == 'Spacer/Flush' ||
      name == 'Tuned® Spacer III' ||
      name == 'Tuned® Defense™ Cement Spacer' ||
      name == 'Tuned® Prime™ Cement Spacer'));
  }

  public get typeName$(): Observable<string> {

    return this.stageState.type$
      .pipe(
        map(t => t ? t.name : null)
      );
  }

  public get mudParameterDisplay$(): Observable<string> {
    return this.stageState.mudParameterDisplay$;
  }

  public get filteredFluids$(): Observable<FluidModel[]> {

    return this._filteredFluids$;
  }

  public get dropdownFluidItems$(): Observable<SelectItem[]> {

    return this.stageState.dropdownFluidItems$;
  }

  public get isFirstStageRemovable(): Observable<boolean> {
    return this._scheduleStateFactory.isFirstStageRemovableObv;
  }

  public get isStageRemovable(): boolean {
    return this.stageState.order !== 0;
  }

  public get isStageInsertable(): boolean {

    return this.isStageRemovable;
  }

  public get slurryId$(): Observable<string> {

    return this.stageState.selectedFluid$
      .pipe(
        map(fluid => {

          return fluid ? fluid.slurryId : null;
        })
      );
  }

  public get slurryName$(): Observable<string> {

    return this.stageState.selectedFluid$
      .pipe(
        map(fluid => {

          return fluid ? fluid.displayName : null;
        })
      );
  }

  public get avgRate$(): Observable<number> {

    return this.stageState.avgRate$;
  }

  public get plannedVolume$(): Observable<number> {

    return this.stageState.plannedVolume$
      .pipe(
        map(v => {

          return v || null;
        })
      );
  }

  public get topOfFluid$(): Observable<number> {

    return this.stageState.topOfFluid$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get plannedScope3Co2e$(): Observable<number> {

    return this.stageState.plannedScope3Co2e$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get actualScope3Co2e$(): Observable<number> {
    return this.stageState.actualScope3Co2e$
      .pipe(
        map(v => {

          return v || 0;
        })
      );
  }

  public get plannedScopeWithBlendCo2e$(): Observable<number> {
    return combineLatest(this.plannedScope3Co2e$, this.stageState.totalBlendCO2e$).pipe(map(([value1, value2]) => {
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2);  
    }));
  }

  public get actualScopeWithBlendCo2e$(): Observable<number> {
    return combineLatest(this.actualScope3Co2e$, this.stageState.totalBlendActualCO2e$).pipe(map(([value1, value2]) => { 
      return (isNaN(value1) ? 0 : value1) + (isNaN(value2) ? 0 : value2); 
    }));
  }

  public showFluidOnCP$(name: string) {
    return this.isFluid$.pipe(switchMap(p => p ? this.isVisible$(name) : of(false)));
  }

  public get loadOutQuantity$(): Observable<number> {

    return this.stageState.loadoutVolume$;
  }

  public get actualVolumePumped$(): Observable<number> {

    return this.stageState.actualVolume$;
  }

  public get dryWeight$(): Observable<number> {

    return this.stageState.dryWeight$;
  }

  public get dryVolume$(): Observable<number> {

    return this.stageState.dryVolume$;
  }

  public get plannedDensity$(): Observable<number> {

    return this.stageState.plannedDensity$;
  }

  public get isOffshoreJob$(): Observable<boolean> {

    return this._viewState.isOffshoreJob$;
  }

  public get isFluidSearchable(): boolean {

    return this._viewState.isScheduleEditView;
  }

  public get isFluidSelectable(): boolean {

    return !this._viewState.isScheduleEditView && !this._viewState.isCP4View;
  }

  public get isActualVolumeEditable(): boolean {

    return this._viewState.isCP4View;
  }

  public get isActualDensityEditable$(): Observable<boolean> {

    return combineLatest([
      this.stageState.isDrillingMud$,
      this.stageState.isPlug$
    ])
      .pipe(
        map(([isDrillingMud, isPlug]) => {

          return !isDrillingMud && !isPlug && this._viewState.isCP4View;
        })
      );
  }

  public get isMudEditable(): boolean {

    return this._viewState.isScheduleEditView || this._viewState.isCP1View;
  }

  public get isShowFluidLink(): boolean {
    return !this._viewState.isCP4View
  }

  public get isFluidLinkDisable$(): Observable<boolean> {
    return combineLatest([
      this.controlPointService.controlPoint1State$,
      this.controlPointService.controlPoint2State$
    ]).pipe(
      map(([cp1State, cp2State]) => {
        if (this.isNewJob)
          return false;

        const isCP1SubmittedOrApproved = cp1State === ControlPointState.Submitted
          || cp1State === ControlPointState.Approved;

        const isCP2PreparedOrCompleted = cp2State === ControlPointState.Prepared
          || cp2State === ControlPointState.Completed;

        if (this._viewState.isCP1View) {
          return isCP1SubmittedOrApproved;
        }

        if (this._viewState.isCP2View) {
          return isCP2PreparedOrCompleted;
        }

        if (this.isScheduleEditView) {
          return isCP1SubmittedOrApproved && isCP2PreparedOrCompleted;
        }

        return false;
      })
    );
  }

  public get isNewJob(): boolean {
    return this.job == null || this.job.id == ''
      || this.job.id == '00000000-0000-0000-0000-000000000000';
  }

  public get isFluidChangeDisabled$(): Observable<boolean> {

    return this._viewState.isCP1Submitted$
      .pipe(
        map(isCp1Submitted => {

          return isCp1Submitted;
        })
      );
  }

  public get stageTypeItems$(): Observable<SelectItem<string>[]> {
    return this.stageState.dropdownStageTypeItems$;
  }

  public get cogsAvailable$(): Observable<boolean> {
    return this.stageState.cogs$
      .pipe(
        map(stageCost => {
          return stageCost.totalCOGS !== null
            // ..and no empty children COGS
            && !stageCost.fluidMaterials.some(x => x.totalCOGS == null);
        })
      );
  }

  public get cogs$(): Observable<number> {

    return this.stageState.cogs$
      .pipe(
        map(stageCost => {
          return stageCost.totalCOGS;
        })
      );
  }

  public get anyMaterialMissingBulkDensity$(): Observable<boolean> {

    return this.stageState.anyMaterialMissingBulkDensity$;
  }

  ngOnInit() {
    this.weight = this.unitConversionService.getCurrentUnitMeasure(UnitType.Weight);
    this.volume = this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume);
    this._filteredFluids$ =
      combineLatest([
        this.stageState.availableFluids$,
        this._fluidSearchCompleteSrc.asObservable()
      ])
        .pipe(
          map(([fluids, searchKey]) => {
            return this._filterFluids(fluids, searchKey);
          }),
          shareReplay()
        );
    this._scheduleStateFactory.updateCurrentStageRemovable();

    this._subscriptions.add(
      this.stageInfoForm.get('slurry').valueChanges.subscribe(fluid => {
        this.stageState.setLinkedFluidActual(this.stageState.isIFactsFluid(fluid) ? fluid : null);
      })
    );

    this._subscriptions.add(
      combineLatest(
      [
        this.stageInfoForm.get('selectedFluidId').valueChanges,
        this.stageState.fluids$
      ]
      ).subscribe(([fluidId, fluids]: [string, FluidModel[]]) => {
        const fluid = fluids.find(f => f.id === fluidId);
        this.stageState.setLinkedFluidActual(this.stageState.isIFactsFluid(fluid) ? fluid : null);
      })
    );

  }

  public ngOnDestroy() {

    if (this.loadingSubscription) {

      this.loadingSubscription.unsubscribe();
    }

    this._subscriptions.unsubscribe();

    this._fluidSearchCompleteSrc.complete();

    this.dynamicComponentService.destroyComponent();
  }

  onFocusOut(input: any): void {

    let plannedVolume = this.stageState.form.get('plannedVolume')?.value;

    if (!plannedVolume)
      return;

    const setQuantity = +input.value;
    plannedVolume = getDisplayValue(plannedVolume, this.unitConversionService.getApiUnitMeasure(UnitType.LargeVolume), this.unitConversionService.getCurrentUnitMeasure(UnitType.LargeVolume));

    if (setQuantity && setQuantity > plannedVolume * 1.25) {

      this.dialogService.open(ActualQuantityConfirmationComponent, {
        header: 'Confirmation',
        width: '500px',
        autoZIndex: true,
        data: {
          plannedQty: Math.round((plannedVolume + Number.EPSILON) * 100) / 100,
          actualQty: Math.round((setQuantity + Number.EPSILON) * 100) / 100
        }
      });

      this.actualQuantityConfirmationService.actualQuantityChange$
        .pipe(first())
        .subscribe(isConfirmed => {

          if (!isConfirmed) {
            this.stageState.form.get('actualVolumePumped').setValue('');
          }
        });
    }
  }

  public onFluidSearchComplete(searchKey): void {
    this._fluidSearchCompleteSrc.next(searchKey);
  }

  public changeFluidName(event): void {
    this.stageState.fluidName$.next({
      id: this.stageInfoForm.value.fluidForm.id === null ? this.stageInfoForm.value.slurry.tempId : this.stageInfoForm.value.fluidForm.id,
      name: event.target.value
    });
  }

  public showCogsHelp(): void {

    this.stageState.showCogsHelp();
  }

  public showMissingBulkDensityHelp(): void {

    this.stageState.showMissingBulkDensityHelp();
  }

  private _filterFluids(fluids: FluidModel[], searchKey: string): FluidModel[] {
    if (!searchKey) {
      return [...fluids];
    }

    return fluids.filter(fluid => {
      if (fluid.displayName)
        return fluid.displayName.toLowerCase().indexOf(searchKey.toLowerCase()) !== -1;
    });
  }

  public openMudParameters(): void {

    this.dynamicComponentService.createComponent(
      MudParametersComponent,
      {
        form: this.stageInfoForm.controls.mudParameter,
        order: this.stageState.order,
        isPumpDefault: this.isPumpDefault
      });

  }

  public insertStageBefore(): void {

    this.stageState.insertStageBefore();
    this._scheduleStateFactory.updateCurrentStageRemovable();
  }

  public insertStageAfter(): void {

    this.stageState.insertStageAfter();
    this._scheduleStateFactory.updateCurrentStageRemovable();
  }

  public deleteStage(): void {

    this.stageState.deleteStage();
  }

  public get listPumpScheduleStageStateManager$(): Observable<PumpScheduleStageStateManager[]> {
    return this._scheduleStateFactory.listPumpScheduleStageStateManager$
  }

  public linkFluidsActual(): void {
    const fluidSelectSubscription = this.stageState.linkFluidsActual().subscribe(fluid$ => {

      this.loadingSubscription = fluid$.subscribe(fluid => {

        if (fluid) {
          this.stageState.setLinkedFluidActual(fluid);
        }

        // There might not be loading subscription returned if linking already linked fluid.
        if (this.loadingSubscription) {

          this.loadingSubscription.unsubscribe();
        }

        this.loadingSubscription = null;
      });

      // Angular does not see field value changes when field (loadingSubscription)
      // is set in other subscription callback
      this._changeDetector.markForCheck();

      fluidSelectSubscription.unsubscribe();
    });

    this._subscriptions.add(fluidSelectSubscription);
  }

  public linkFluids(): void {
    const fluidSelectSubscription = this.stageState.linkFluids().subscribe(fluid$ => {

      this.loadingSubscription = fluid$.subscribe(fluid => {

        if (fluid) {
          this.stageState.setLinkedFluid(fluid);
        }

        // There might not be loading subscription returned if linking already linked fluid.
        if (this.loadingSubscription) {

          this.loadingSubscription.unsubscribe();
        }

        this.loadingSubscription = null;
      });

      // Angular does not see field value changes when field (loadingSubscription)
      // is set in other subscription callback
      this._changeDetector.markForCheck();

      fluidSelectSubscription.unsubscribe();
    });

    this._subscriptions.add(fluidSelectSubscription);
  }

  public validateFluidValue() {
    const control = this.stageInfoForm.get('slurry') as UntypedFormControl;

    if (control) {

      if (control.value && isPrimitive(control.value)) {
        control.setErrors({ 'ivalidValue': true });
      }
      else {
        control.setErrors(null);
      }
    }
  }

  transformResult(value) {
    return this.unitConversionService.ConvertEmissionsToUserUnit(value, this.weight, this.volume);
  }
} 
